import { Form, Input } from '@components'
import { CardHeader, FormLoading } from '@components/common'
import Addview from '@components/form/addview';
import { Settings } from '@hooks';
import { Fragment } from 'react'

const Apps = () => {
    const { links, onSave, loading } = Settings.useAppLinks();
    return (
        <Fragment>
            <CardHeader label='لینک های دانلود برنامه' />
            {loading ? <FormLoading /> :
                <div className='my-10 '>
                    <Form
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={onSave}>
                        <Addview
                            defultValue={links}
                            label="لینک های دانلود"
                            render={(view: any) => {
                                return (
                                    <div className="my-5" key={Math.random()}>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mb-4'>
                                            <Input.Defualt
                                                labelProps={{ children: "نام برنامه" }}
                                                inputProps={{
                                                    name: `name[${view.id || view}]`,
                                                    placeholder: "نام برنامه را وارد نمایید",
                                                    defaultValue: view?.name
                                                }}
                                            />
                                            <Input.Defualt
                                                inputProps={{
                                                    name: `url[${view.id || view}]`,
                                                    placeholder: "لینک را وارد نمایید",
                                                    defaultValue: view?.url
                                                }}
                                                labelProps={{ children: "لینک دانلود" }}
                                            />
                                        </div>
                                        <Input.Preview
                                            labelProps={{ children: "انتخاب آیکون" }}
                                            inputProps={{ name: `image[${view.id || view}]` }}
                                            defaultValue={view?.image}
                                        />
                                    </div>
                                )
                            }}
                        />
                    </Form>
                </div>
            }
        </Fragment>
    )
}


export default Apps