import { Form } from "@components";
import { FormLoading } from "@components/common"
import AddView from "@components/form/addview";
import Input from "@components/form/input";
import { Settings } from "@hooks";
import { Fragment } from "react"

const HeaderSetting = () => {
    const { links, onSave, loading } = Settings.useHeaderLinks();
    return (
        <Fragment>
            {loading ? <FormLoading /> :
                <div className="my-4">
                    <Form
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={onSave}>
                        <AddView
                            label='تنظیم هدر سایت'
                            defultValue={links}
                            render={(view: any) => {
                                return (
                                    <div key={Math.random()}>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                            <Input.Defualt
                                                inputProps={{
                                                    name: `title[${view.id || view}]`,
                                                    placeholder: "عنوان را وارد نمایید",
                                                    defaultValue: view?.title
                                                }}
                                                labelProps={{ children: "عنوان" }}
                                            />
                                            <Input.Defualt
                                                inputProps={{
                                                    name: `link[${view.id || view}]`,
                                                    placeholder: "آدرس را وارد نمایید",
                                                    defaultValue: view?.link
                                                }}
                                                labelProps={{ children: "آدرس" }}
                                            />
                                            <Input.Defualt
                                                inputProps={{
                                                    name: `order[${view.id || view}]`,
                                                    placeholder: "ترتیب را مشخص کنید",
                                                    defaultValue: view?.order
                                                }}
                                                labelProps={{ children: "ترتیب" }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            }
                        />
                    </Form>
                </div>
            }
        </Fragment >
    )
}

export default HeaderSetting