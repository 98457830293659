import { Form } from "@components";
import { FormLoading } from "@components/common";
import AddView from "@components/form/addview";
import Input from "@components/form/input";
import { Settings } from "@hooks";
import { Fragment } from "react"

const HeaderSetting = () => {
    const { sections, onSave, loading } = Settings.useHomePage();
    return (
        <Fragment>
            {loading ? <FormLoading /> :
                <div className="my-4">
                    <Form
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={onSave}>
                        <AddView
                            label='تنظیم بخش های صفحه اصلی'
                            defultValue={sections}
                            render={(view: any) => {
                                return (
                                    <div key={Math.random()}>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                            <Input.Defualt
                                                inputProps={{
                                                    name: `title[${view.id || view}]`,
                                                    placeholder: "عنوان را وارد نمایید",
                                                    defaultValue: view?.title
                                                }}
                                                labelProps={{ children: "عنوان" }}
                                            />
                                            <Input.Select
                                                options={[{
                                                    label: "ویدئو",
                                                    value: "Video"
                                                },
                                                {
                                                    label: "موزیک و موزیک ویدئو",
                                                    value: "MusicVideo"
                                                },
                                                {
                                                    label: "هنرمند",
                                                    value: "Artist"
                                                },
                                                ]}
                                                name={`rel_type[${view.id || view}]`}
                                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                                labelProps={{
                                                    children: "بخش دسته بندی"
                                                }}
                                                defaultValue={view?.rel_type}
                                            />
                                            <Input.Select
                                                url='/manager/settings/create?select=category '
                                                name={`category_slug[${view.id || view}]`}
                                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                                labelProps={{
                                                    children: "دسته بندی"
                                                }}
                                                defaultValue={view?.category_slug}
                                            />
                                            <Input.Defualt
                                                inputProps={{
                                                    name: `order[${view.id || view}]`,
                                                    placeholder: "ترتیب را مشخص کنید",
                                                    defaultValue: view?.order
                                                }}
                                                labelProps={{ children: "ترتیب" }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            }
                        />
                    </Form>
                </div>
            }
        </Fragment>
    )
}

export default HeaderSetting