import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"
interface Props extends PropsWithChildren {
    menus: Array<{
        to: string,
        label: string,
        type?: 'list' | 'form'
    }>
}
const Menu = ({ children, menus }: Props) => {
    return (
        <div className="lg:grid  lg:grid-cols-5 gap-y-3 lg:gap-y-0 gap-x-3">
            <div className="lg:sticky top-[80px] start-0 self-start grid gap-y-5 menu lg:col-start-1 lg:col-end-2 bg-white px-5 py-10 rounded-2xl">
                {menus.map((menu, i) => {
                    return (
                        <Link to={menu.to} className="" key={i}>
                            <div className="flex items-center justify-between ">
                                <div className="flex items-center gap-x-3">
                                    <div className={`w-[15px] h-[15px] rounded-full ${menu.type === 'list' ? "bg-primary500" : "bg-secondary500"}`} />
                                    <div className="text-[14px] font-medium">{menu.label}</div>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
                                        <path d="M7.79325 0.206748C8.04386 0.457352 8.06664 0.849506 7.8616 1.12585L7.79325 1.20502L1.70447 7.29412L7.79325 13.3832C8.04386 13.6338 8.06664 14.026 7.8616 14.3023L7.79325 14.3815C7.54265 14.6321 7.15049 14.6549 6.87415 14.4498L6.79498 14.3815L0.206748 7.79325C-0.0438557 7.54265 -0.066638 7.15049 0.138402 6.87415L0.206748 6.79498L6.79498 0.206748C7.07065 -0.0689161 7.51759 -0.0689161 7.79325 0.206748Z" fill="black" />
                                    </svg>
                                </div>

                            </div>
                        </Link>
                    )
                })}
            </div>
            <div className="content my-4 lg:my-0 lg:col-start-2 self-start lg:col-end-6 bg-white p-3 rounded-lg">
                {children}
            </div>
        </div>
    )
}

export default Menu