import { Form } from "@components"
import { CardHeader, FormLoading } from "@components/common"
import Input from "@components/form/input"
import { API } from "@hooks"
import { Fragment, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router"
const Creation = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { item, find, setItem, loading, setLoading, update, store } = API.useApi();
    const { id } = useParams();

    let paginate = new URLSearchParams(location.search).get('paginate');
    useEffect(() => {
        setLoading(true)
        if (id) {
            find('/manager/sliders', id)
        } else {
            setItem(null);
            setTimeout(() => {
                setLoading(false);
            }, 10);
        }
    }, [id])
    console.log(item?.page)
    return (
        <Fragment>
            <CardHeader label={id ? 'ویرایش اسلایدر' : 'ایجاد اسلایدر'} />
            {loading ? <FormLoading /> :
                <div className='my-10 '>
                    <Form
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={
                            async (data: FormData) => {
                                if (id) {
                                    data.append('_method', 'PUT');
                                    await update('manager/sliders', data, id);
                                    if (paginate) {
                                        navigate(`/sliders?paginate=${paginate}`)
                                    } else {
                                        navigate(`/sliders`)
                                    }
                                } else {
                                    await store('manager/sliders', data);
                                    if (paginate) {
                                        navigate(`/sliders?paginate=${paginate}`)
                                    } else {
                                        navigate(`/sliders`)
                                    }
                                }
                            }
                        }>
                        <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-5'>
                            <Input.Select
                                url='/manager/sliders/create?select=slidercategory'
                                name='page'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "صفحه"
                                }}
                                defaultValue={item?.page}
                            />
                            <Input.Defualt
                                inputProps={{ name: 'alt', placeholder: "الت عکس را وارد نمایید", defaultValue: item?.alt }}
                                labelProps={{ children: "الت عکس کاور" }}
                            />
                            <Input.Defualt
                                inputProps={{ name: 'href', placeholder: "ادرس  را وارد نمایید", defaultValue: item?.href }}
                                labelProps={{ children: "ادرس" }}
                            />
                        </div>
                        <Input.Preview
                            defaultValue={item?.image}
                            labelProps={{ children: "تصویر" }}
                            inputProps={{ name: "image" }}
                        />
                    </Form>
                </div>
            }
        </Fragment >
    )
}

export default Creation