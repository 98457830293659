import React from 'react'

const FormLoading = () => {
    return (
        <div className='grid grid-cols-1 animate-pulse lg:grid-cols-3 md:grid-cols-2 gap-5'>
            {
                Array.from(Array(9).keys()).map((x) =>
                    <div key={x}>
                        <div className="rounded-lg bg-white100 h-4 w-[30%] my-2"></div>
                        <div className="rounded-lg bg-white100 h-10"></div>
                    </div>
                )
            }
            <div className="rounded-lg bg-white100 w-[50%] col-start-3 ms-auto h-10"></div>
        </div>
    )
}

export default FormLoading