import React from 'react'
import { Link } from 'react-router-dom'
interface Props {
    label: string,
    textButton?: string,
    toButton?: string
}
const CardHeader = ({ label, textButton, toButton }: Props) => {
    return (
        <div className='flex items-center m-3 justify-between'>
            <h3 className='sm:text-lg text-[16px] font-semibold'>{label}</h3>
            {textButton ? <div className='bg-white50 hidden md:block px-2 whitespace-nowrap py-2 text-xs rounded-lg'><Link to={toButton || ""} className='text-sm'>{textButton}</Link></div> : null}
        </div>
    )
}

export default CardHeader