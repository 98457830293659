import { Form, Input } from '@components';
import { CardHeader } from '@components/common';
import { Settings } from '@hooks';
import React, { useState } from 'react'

const Profile = () => {
    const { user, onSave } = Settings.useProfileSettings();
    const [image, setImage] = useState<File | null>(null);
    return (
        <div>
            <CardHeader label="ویرایش اطلاعات کاربری" />

            <Form
                formProps={{ className: 'flex flex-col' }}
                buttonProps={{ children: "ویرایش اطلاعات", className: 'self-center px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                onSend={onSave}>
                <div className='flex items-center justify-center'>
                    <div className='w-[80px] relative h-[80px] bg-slate-300 rounded-full overflow-hidden'>
                        <input type="file" name="profile" onChange={({ target }) => {
                            if (target.files?.length) {
                                setImage(target.files[0])
                            } else {
                                setImage(null);
                            }
                        }} className='absolute w-full h-full z-10 opacity-0' />
                        <img src={image ? URL.createObjectURL(image) : user?.profile} className='absolute w-full h-full object-cover' />
                    </div>
                </div>
                <div className="w-[100%] lg:w-[35%] mx-auto my-10">
                    <Input.Defualt
                        inputProps={{ name: 'name', placeholder: "نام و نام خانوادگی را وارد نمایید", defaultValue: user?.name }}
                        labelProps={{ children: "نام و نام خانوادگی" }}
                    />
                    <Input.Defualt
                        inputProps={{ name: 'username', placeholder: "نام کاربری  را وارد نمایید", defaultValue: user?.username }}
                        labelProps={{ children: "نام کاربری" }}
                    />

                    <Input.Defualt
                        inputProps={{ name: 'moblie', placeholder: "شماره موبایل  را وارد نمایید", defaultValue: user?.mobile }}
                        labelProps={{ children: "شماره موبایل" }}
                    />

                    <Input.Password
                        inputProps={{ name: 'password', placeholder: "کلمه عبور  را وارد نمایید", }}
                        labelProps={{ children: "کلمه عبور" }}
                    />
                </div>
            </Form>
        </div>
    )
}

export default Profile