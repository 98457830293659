
import { Menu } from '@components/layout'
import { Route, Routes } from 'react-router'
import List from './list'
import Creation from './creation'

const Categories = () => {

    return (
        <Menu menus={[
            {
                label: 'لیست دسته بندی ها',
                to: '/categories',
                type: "list"
            },
            {
                label: 'ایجاد دسته بندی',
                to: '/categories/add'
            }
        ]}>
            <Routes>
                <Route path='/' element={<List />} />
                <Route path='/add' element={<Creation />} />
                <Route path='/edit/:id' element={<Creation />} />
            </Routes>
        </Menu>
    )
}

export default Categories