import { Fragment, useEffect, useState } from 'react'
import { AsyncDelete, CardHeader, Paginate, SearchBox, Table } from '@components/common'
import { Link, useLocation } from 'react-router-dom'
import Icons from '@assets/icons'
import { queryGenerate } from '@components/utils'
import { API } from '@hooks'
const List = () => {
    const { list, loading, paginate, index, destroy } = API.useApi();
    const { search } = useLocation();
    const [query, setQuery] = useState<string>();
    const [text, setText] = useState<string>('');

    useEffect(() => {
        let new_week = new URLSearchParams(search).get('new_week');
        let newQuery = "";
        if (new_week) {
            newQuery += "?new_week=1"
        }
        if (text.length) {
            newQuery += "?text=" + text
        }
        setQuery(newQuery)
    }, [search, text]);

    let changeablepaginate = new URLSearchParams(search).get('paginate');
    useEffect(() => {
        let querypaginate = new URLSearchParams(search).get('paginate');
        const timeOutId = setTimeout(() => index(queryGenerate(querypaginate ? querypaginate + query : '/manager/videos' + query)), 100);
        return () => clearTimeout(timeOutId);

    }, [query, changeablepaginate])

    return (
        <Fragment>
            <CardHeader label='لیست ویدئو ها' textButton='ایجاد ویدئو جدید' toButton='/videos/add' />
            <div className='my-10'>
                <div className='mb-5 px-2 grid lg:grid-cols-4'>
                    <SearchBox onChange={(value: string) => {
                        setText(value)
                    }} />
                </div>
                <Table loading={loading} head={[
                    {
                        label: "تصویر"
                    },
                    {
                        label: "نام ویدئو"
                    },
                    // {
                    //     label: "نام هنرمند"
                    // },
                    {
                        label: "نام دسته بندی"
                    },
                    {
                        label: "مدیریت"
                    },
                ]}
                    rows={list || []}
                    renderItem={(video: any, i: number) => {

                        return (
                            <tr className='text-center' key={i}>
                                <td>
                                    <div className='flex justify-center'>
                                        <img className='w-[50px] h-[50px] object-cover rounded-lg' src={video.cover || require('@images/no-photo.png')} alt={video.name} />
                                    </div>
                                </td>
                                <td>{video.name}</td>
                                {/* <td>{video.artist?.user?.name}</td> */}
                                <td>{video.category?.name}</td>
                                <td >
                                    <div className='flex justify-center items-center gap-x-5'>
                                        <AsyncDelete onDestroy={async () => await destroy('/manager/videos', video.id)} />
                                        <Link to={changeablepaginate ? '/videos/edit/' + video.id + "?paginate=" + changeablepaginate : '/videos/edit/' + video.id}>
                                            <Icons.Edit />
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        )
                    }}
                />
            </div>
            <Paginate {...paginate} onChange={(link: string) => {
                index(queryGenerate(link + query));
            }} />

        </Fragment >

    )
}

export default List;