import { useEffect, useState } from "react";
import useRequest from "./request";
import toast from "react-hot-toast";
import { useAuthContext } from "@context";


const useSettings = () => {

    const [settings, setSettings] = useState<any>([])
    const [loading, setloading] = useState<boolean>(false)

    const request = useRequest();
    const onSave = async (data: FormData) => {
        await request.send({ url: "/manager/settings", data }).then((response) => {
            if (response) {
                toast.success('درخواست با موفقیت انجام شد')
            }
        })
    }
    const onCreate = async () => {
        setloading(true)
        await request.send({ url: '/manager/settings', method: "GET" }).then((response) => {
            setloading(false)
            if (response) {
                setSettings(response);
            }
        })
    }


    useEffect(() => {
        onCreate()
    }, [])



    return { onSave, settings, loading }

}


const useProfileSettings = () => {
    const { login, user } = useAuthContext();

    const request = useRequest();
    const onSave = async (data: FormData) => {
        data.append('_method', 'PUT')
        await request.send({ url: "/user/edit", data }).then((response) => {
            if (response) {
                login(response)
                toast.success('درخواست با موفقیت انجام شد')
            }
        })
    }


    return {
        user,
        onSave
    }
}


const useAppLinks = () => {
    const [links, setLinks] = useState<any>([])
    const [loading, setloading] = useState<boolean>(false);
    const request = useRequest();
    const onSave = async (data: FormData) => {
        await request.send({ url: "/manager/apps", data }).then((response) => {
            if (response) {
                toast.success('درخواست با موفقیت انجام شد')
            }
        })
    }

    const onCreate = async () => {
        setloading(true)
        await request.send({ url: '/manager/apps', method: "GET" }).then((response) => {
            setloading(false)
            if (response) {
                setLinks(response);
            }
        })
    }


    useEffect(() => {
        onCreate()
    }, [])


    return { links, loading, onSave }

}

const useContactUs = () => {
    const [links, setLinks] = useState<any>([])
    const [loading, setloading] = useState<boolean>(false);
    const request = useRequest();
    const onSave = async (data: FormData) => {
        await request.send({ url: "/manager/contactus", data }).then((response) => {
            if (response) {
                toast.success('درخواست با موفقیت انجام شد')
            }
        })
    }

    const onCreate = async () => {
        setloading(true)
        await request.send({ url: '/manager/contactus', method: "GET" }).then((response) => {
            setloading(false)
            if (response) {
                setLinks(response);
            }
        })
    }


    useEffect(() => {
        onCreate()
    }, [])


    return { links, loading, onSave }

}

const useHeaderLinks = () => {
    const [links, setlinks] = useState<any>([])
    const [loading, setloading] = useState<boolean>(false);
    const request = useRequest();
    const onSave = async (data: FormData) => {
        await request.send({ url: "/manager/headerlinks", data }).then((response) => {
            if (response) {
                toast.success('درخواست با موفقیت انجام شد')
            }
        })
    }

    const onCreate = async () => {
        setloading(true)
        await request.send({ url: '/manager/headerlinks', method: "GET" }).then((response) => {
            setloading(false)
            if (response) {
                setlinks(response);
            }
        })
    }


    useEffect(() => {
        onCreate()
    }, [])


    return { links, loading, onSave, }

}

const useHomePage = () => {
    const [sections, setsections] = useState<any>([])
    const [loading, setloading] = useState<boolean>(false);
    const request = useRequest();
    const onSave = async (data: FormData) => {
        await request.send({ url: "/manager/homepage", data }).then((response) => {
            if (response) {
                toast.success('درخواست با موفقیت انجام شد')
            }
        })
    }

    const onCreate = async () => {
        setloading(true)
        await request.send({ url: '/manager/homepage', method: "GET" }).then((response) => {
            setloading(false)
            if (response) {
                setsections(response);
            }
        })
    }


    useEffect(() => {
        onCreate()
    }, [])


    return { sections, loading, onSave, }

}

export { useSettings, useProfileSettings, useAppLinks, useContactUs, useHomePage, useHeaderLinks }