import { Fragment, useEffect, useState } from 'react'
import { AsyncDelete, CardHeader, Paginate, SearchBox, Table } from '@components/common'
import { Link, useLocation } from 'react-router-dom'
import Icons from '@assets/icons'
import { API } from '@hooks'
import { queryGenerate } from '@components/utils'
const List = () => {
    const { list, loading, paginate, index, destroy } = API.useApi();
    const { search } = useLocation();
    let type = new URLSearchParams(search).get('type')
    const [query, setQuery] = useState<string>("?type=music")
    const [text, setText] = useState<string>('')
    useEffect(() => {
        let new_week = new URLSearchParams(search).get('new_week');
        let newQuery = "";
        if (type) {
            newQuery += "?type=" + type;
        } else {
            newQuery += "?type=music";
        }
        if (new_week) {
            newQuery += "?new_week=1"
        }

        if (text.length) {
            newQuery += "?text=" + text
        }
        setQuery(newQuery)


    }, [type, search, text])
    let changeablepaginate = new URLSearchParams(search).get('paginate');
    useEffect(() => {
        let querypaginate = new URLSearchParams(search).get('paginate');
        const timeOutId = setTimeout(() => index(queryGenerate(querypaginate ? querypaginate + query : '/manager/musics' + query)), 100);
        return () => clearTimeout(timeOutId);

    }, [query, changeablepaginate])

    return (
        <Fragment>
            <CardHeader label={type ? 'لیست موزیک ویدیو ها' : 'لیست موزیک ها'} textButton={type ? 'ایجاد موزیک ویدیو جدید ' : 'ایجاد موزیک جدید'} toButton={type ? '/music-videos/add' : '/musics/add'} />
            <div className='my-10'>
                <div className='mb-5 px-2 grid lg:grid-cols-4'>
                    <SearchBox onChange={(value: string) => {
                        setText(value)
                    }} />
                </div>
                <Table loading={loading} head={
                    [
                        {
                            label: "تصویر"
                        },
                        {
                            label: "نام موزیک"
                        },
                        {
                            label: "نام خواننده"
                        },
                        {
                            label: "دسته بندی"
                        },
                        {
                            label: "مدیریت"
                        },
                    ]}
                    rows={list || []}
                    renderItem={(music: any, i: number) => {

                        return (
                            <tr className='text-center' key={i}>
                                <td>
                                    <div className='flex justify-center'>
                                        <img className='w-[50px] h-[50px] object-cover rounded-lg' src={music?.cover || require('@images/no-photo.png')} alt={music.name} />
                                    </div>
                                </td>
                                <td>{music?.name}</td>
                                <td>{music?.artistinfo?.userinfo?.name}</td>
                                <td>{music?.category?.name}</td>
                                <td >
                                    <div className='flex justify-center items-center gap-x-5'>
                                        <AsyncDelete onDestroy={async () => await destroy('/manager/musics', music.id)} />
                                        {
                                            changeablepaginate ?
                                                <Link to={type ? '/musics/music-videos/edit/' + music.id + '?type=video' + "&paginate=" + changeablepaginate : '/musics/edit/' + music.id + "?paginate=" + changeablepaginate}>
                                                    <Icons.Edit />
                                                </Link>
                                                :
                                                <Link to={type ? '/musics/music-videos/edit/' + music.id + '?type=video' : '/musics/edit/' + music.id}>
                                                    <Icons.Edit />
                                                </Link>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    }}
                />
            </div>
            <Paginate {...paginate} onChange={(link: string) => {
                index(queryGenerate(link + query));
            }} />
        </Fragment >
    )
}

export default List;