
import { Menu } from '@components/layout'
import { Route, Routes } from 'react-router'
import List from './list'
import New from './new'
import Creation from './creation'
const Musics = () => {

    return (
        <Menu menus={[
            {
                label: 'لیست محتوا جدید',
                to: '/musics/new',
                type: "list"
            },
            {
                label: 'لیست موزیک ویدئو ها',
                to: '/musics/music-videos?type=video',
                type: "list"
            },
            {
                label: 'ایجاد موزیک ویدئو',
                to: '/musics/music-videos/add?type=video'
            },
            {
                label: 'لیست موزیک ها',
                to: '/musics',
                type: "list"
            },
            {
                label: 'ایجاد موزیک',
                to: '/musics/add'
            }
        ]}>
            <Routes>
                <Route path='/' element={<List />} />
                <Route path='/new' element={<New />} />
                <Route path='/add' element={<Creation />} />
                <Route path='/edit/:id' element={<Creation />} />
                <Route path='/music-videos' element={<List />} />
                <Route path='/music-videos/add' element={<Creation />} />
                <Route path='/music-videos/edit/:id' element={<Creation />} />
            </Routes>
        </Menu>
    )
}

export default Musics