import { Form, Input } from '@components'
import { CardHeader, FormLoading } from '@components/common'
import { API } from '@hooks'
import { Fragment, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

const Creation = () => {
    const navigate = useNavigate()
    const location = useLocation();

    const { item, find, setItem, loading, setLoading, update, store } = API.useApi();
    const { id } = useParams();
    useEffect(() => {
        setLoading(true)
        if (id) {
            find('/manager/artists', id)
        } else {
            setItem(null);
            setTimeout(() => {
                setLoading(false);
            }, 10);
        }
    }, [id])

    let paginate = new URLSearchParams(location.search).get('paginate');

    return (
        <Fragment>
            <CardHeader label={id ? 'ویرایش هنرمند' : 'ایجاد هنرمند جدید'} textButton='لیست هنرمندان' toButton='/artists' />
            {loading ? <FormLoading /> :
                <div className='my-10 '>
                    <Form
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={async (data: FormData) => {
                            if (id) {
                                data.append('_method', 'PUT');
                                await update('manager/artists', data, id);
                                if (paginate) {
                                    navigate(`/artists?paginate=${paginate}`)
                                } else {
                                    navigate(`/artists`)
                                }
                            } else {
                                await store('manager/artists', data);
                                if (paginate) {
                                    navigate(`/artists?paginate=${paginate}`)
                                } else {
                                    navigate(`/artists`)
                                }
                            }
                        }}>
                        <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-5'>
                            <Input.Defualt
                                inputProps={{ name: 'name', placeholder: "نام هنرمند را وارد نمایید", defaultValue: item?.user?.name }}
                                labelProps={{ children: "نام هنرمند" }}
                            />
                            <Input.Defualt
                                inputProps={{ name: 'mobile', placeholder: "شماره تماس را وارد نمایید", defaultValue: item?.user?.mobile, type: 'number' }}
                                labelProps={{ children: "شماره تماس" }}
                            />
                            <Input.Defualt
                                inputProps={{ name: 'username', placeholder: "نام کاربری را وارد نمایید", defaultValue: item?.user?.username }}
                                labelProps={{ children: "نام کاربری" }}
                            />
                            <Input.Defualt
                                inputProps={{ name: 'seo[title]', placeholder: "عنوان صفحه  را وارد نمایید", defaultValue: item?.seo?.title }}
                                labelProps={{ children: "عنوان صفحه " }}
                            />
                            <Input.Select
                                url='/manager/artists/create?select=type'
                                name='type_id'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "سبک فعالیت"
                                }}
                                defaultValue={item?.type_id}
                            />
                            <Input.Select
                                url='/manager/artists/create?select=category'
                                name='category_id'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "دسته بندی"
                                }}
                                defaultValue={item?.category_id}
                            />

                            <Input.Password
                                inputProps={{ name: "password", placeholder: "کلمه عبور را وارد نمایید" }}
                                labelProps={{ children: "کلمه عبور ", className: "text-sm font-medium md:text-md" }}
                            />

                            <Input.Select
                                options={[
                                    {
                                        label: "غیر فعال",
                                        value: 0
                                    },
                                    {
                                        label: "فعال",
                                        value: 1
                                    }
                                ]}
                                name='home_visibility'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "نمایش در صفحه اصلی"
                                }}
                                defaultValue={item?.home_visibility}
                            />
                            <Input.Select
                                options={[
                                    {
                                        label: "غیر فعال",
                                        value: 0
                                    },
                                    {
                                        label: "فعال",
                                        value: 1
                                    }
                                ]}
                                name='status'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "وضعیت"
                                }}
                                defaultValue={item?.user?.status}
                            />
                            <Input.Defualt
                                inputProps={{ name: "info[bio]", placeholder: "بیوگرافی را وارد نمایید", defaultValue: item?.user?.info?.bio }}
                                labelProps={{ children: "بیوگرافی" }}
                                containerClassName='lg:col-span-3 md:col-span-3'
                            />
                        </div>
                        <div className='my-5'>
                            <Input.Textarea
                                inputProps={{ name: "seo[schema]", style: { direction: "ltr", textAlign: "left" }, placeholder: "schema code  ...", defaultValue: item?.seo?.schema }}
                                labelProps={{ children: "اسکیما " }}
                            />

                        </div>
                        <div className='my-5'>
                            <Input.Textarea
                                inputProps={{ name: "seo[description]", placeholder: "متن را وارد نمایید", defaultValue: item?.seo?.description }}
                                labelProps={{ children: "توضیحات سئو " }}
                            />
                        </div>
                        <div className='my-5'>
                            <Input.Preview
                                defaultValue={item?.user?.profile}
                                labelProps={{ children: "عکس پروفایل" }}
                                inputProps={{ name: "profile" }}
                            />
                        </div>
                    </Form>

                </div>
            }
        </Fragment>
    )
}

export default Creation