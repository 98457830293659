import { Fragment, useEffect, useState } from 'react'
import { AsyncDelete, CardHeader, Paginate, SearchBox, Table } from '@components/common'
import { Link, useLocation } from 'react-router-dom'
import Icons from '@assets/icons'
import { API } from '@hooks'
import { queryGenerate } from '@components/utils'
const List = () => {
    const { list, loading, paginate, index, destroy } = API.useApi();
    const { search } = useLocation();
    const [query, setQuery] = useState<string>()
    const [text, setText] = useState<string>('')
    useEffect(() => {

        let newQuery = "";
        if (text.length) {
            newQuery += "?text=" + text;
        }

        setQuery(newQuery)

    }, [search, text])

    let changeablepaginate = new URLSearchParams(search).get('paginate');
    useEffect(() => {
        let querypaginate = new URLSearchParams(search).get('paginate');
        const timeOutId = setTimeout(() => index(queryGenerate(querypaginate ? querypaginate + query : '/manager/categories' + query)), 100);
        return () => clearTimeout(timeOutId);

    }, [query, changeablepaginate])
    return (
        <Fragment>
            <CardHeader label='لیست دسته بندی ها' textButton='ایجاد دسته جدید' toButton='/categories/add' />
            <div className='my-10'>
                <div className='mb-5 px-2 grid lg:grid-cols-4'>
                    <SearchBox onChange={(value: string) => {
                        setText(value)
                    }} />
                </div>
                <Table loading={loading} head={[
                    {
                        label: "تصویر"
                    },
                    {
                        label: "نام دسته بندی"
                    },
                    {
                        label: "نام بخش"
                    },
                    {
                        label: "وضعیت نمایش"
                    },
                    {
                        label: "مدیریت"
                    },
                ]}
                    rows={list || []}
                    renderItem={(category: any, i: number) => {

                        return (
                            <tr className='text-center' key={i}>
                                <td>
                                    <div className='flex justify-center'>
                                        <img className='w-[50px] h-[50px] object-cover rounded-lg' src={category.category_data?.cover || require('@images/no-photo.png')} alt={category.name} />
                                    </div>
                                </td>
                                <td>{category.name}</td>
                                <td>{category.part?.name}</td>
                                <td>{category.status ? "فعال" : "غیر فعال"}</td>
                                <td >
                                    <div className='flex justify-center items-center gap-x-5'>
                                        <AsyncDelete onDestroy={async () => await destroy('/manager/categories', category.id)} />
                                        <Link to={changeablepaginate ? '/categories/edit/' + category.id + "?paginate=" + changeablepaginate : '/categories/edit/' + category.id}>
                                            <Icons.Edit />
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        )
                    }}
                />
            </div>
            <Paginate {...paginate} onChange={(link: string) => {
                index(queryGenerate(link + query));
            }} />
        </Fragment >
    )
}

export default List;