import { PropsWithChildren, createContext, useContext, useState } from 'react'


interface user {
    token: string,
    profile: string,
    name: string,
    username: string,
    mobile: string
}

interface ProviderValue {
    user: user | null
    login: Function,
    logout: Function
}
const Context = createContext<ProviderValue | null>(null);
const AuthContext = (props: PropsWithChildren) => {

    const [user, setUser] = useState<user | null>(localStorage.getItem('user') && String(localStorage.getItem('user')).length ? JSON.parse(String(localStorage.getItem('user'))) : null)

    const login = (data: user) => {
        setUser(data);
        localStorage.setItem('user', JSON.stringify(data))
    }
    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
    }
    return (
        <Context.Provider value={{ login, logout, user }}>{props.children}</Context.Provider>
    )
}

export default AuthContext

export function useAuthContext(): ProviderValue {
    return useContext(Context) || { user: null, login: () => { }, logout: () => { } };
} 