import { Input } from '@components'
import React, { useEffect, useState } from 'react'
interface Props {
    onChange: Function
}
const SearchBox = ({ onChange }: Props) => {
    const [query, setQuery] = useState<string>("");
    useEffect(() => {
        const timeOutId = setTimeout(() => onChange(query), 1000);
        return () => clearTimeout(timeOutId);
    }, [query]);
    return (
        <div>
            <Input.Defualt
                inputProps={{
                    placeholder: "جستجو کنید ...",
                    onChange: ({ target: { value } }) => {
                        setQuery(value)
                    }
                }}
            />
        </div>
    )
}

export default SearchBox