import React from 'react';
import moment, { Moment } from 'moment-jalaali'
import { useLocation } from 'react-router';
moment.loadPersian();


function toJalali(date: Date, format: string) {
    return moment(date).format(format)
}


function useReactPath() {
    const location = useLocation()
    const [path, setPath] = React.useState<string>(location.pathname);
    React.useEffect(() => {
        setPath(location.pathname);
    }, [location.pathname]);
    return path || '';
};


function queryGenerate(url: string) {

    const queries = String(url).split('?');
    let newUrl = "";
    let count = 0;
    queries.map((query, i) => {
        if (query.length) {
            if (i == 0) {
                newUrl += query;
            } else {
                const value = query.split('=');
                if (value[1].length) {
                    if (count) {
                        newUrl += '&' + query;
                    } else {
                        newUrl += '?' + query;
                    }
                    count++;
                }
            }
        }
    })
    return newUrl;
}

const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
    pTeNumbers = function (str: string): string {
        if (typeof str === 'string') {
            for (var i = 0; i < 10; i++) {
                str = str.replace(persianNumbers[i], String(i)).replace(arabicNumbers[i], String(i));
            }
        }
        return str;
    };


export { toJalali, moment, useReactPath, queryGenerate, pTeNumbers }