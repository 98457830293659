import { useAuthContext } from "@context"
import useRequest from "./request";

const useLogin = () => {

    const { login } = useAuthContext();
    const request = useRequest();

    const onLogin = async (data: FormData) => {
        await request.send({ url: "/auth_admin", data }).then((response) => {
            if (response) {
                login(response);
            }
        })
    }

    return { onLogin }

}



export { useLogin }
