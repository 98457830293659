import { useState } from "react";

interface PropsDuration {
    file: File,
    type: "audio" | "video"
}

const useFileManager = () => {

    const [result, setResult] = useState<any>()

    const getDuration = ({ file, type }: PropsDuration) => {
        const tpHHMMSS = (time: any) => {
            let sec_num = parseInt(time, 10); // don't forget the second param
            let hours: any = Math.floor(sec_num / 3600);
            let minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
            let seconds: any = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return hours + ':' + minutes + ':' + seconds;
        }
        var item = window.document.createElement(type);
        item.preload = 'metadata';

        item.onloadedmetadata = function () {
            window.URL.revokeObjectURL(item.src);
            setResult(tpHHMMSS(item.duration));
        }
        item.src = URL.createObjectURL(file);;

    }

    function dataURItoBlob(dataURI: any) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }
    const generateVideoThumbnail = (file: File): Promise<{ file: File, url: string }> => {
        return new Promise((resolve) => {
            const canvas = window.document.createElement("canvas");
            const video = window.document.createElement("video");

            // this is important
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx: any = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve({ file: blobToFile(dataURItoBlob(canvas.toDataURL("image/png")), 'image-' + Math.random() * 1000000), url: canvas.toDataURL("image/png") });
            };
        });
    };
    const blobToFile = (theBlob: Blob, fileName: string): File => {
        let file = new File([theBlob], fileName + ".jpg", { type: "image/jpeg", lastModified: new Date().getTime() });
        return file;
    }
    return {
        result,
        getDuration,
        generateVideoThumbnail
    }
}


export { useFileManager }