import { Route, Routes } from 'react-router'
import List from './list'
import Creation from './creation'

const Artists = () => {

    return (

        <Routes>
            <Route path='/' element={<List />} />
            <Route path='/add' element={<Creation />} />
            <Route path='/edit/:id' element={<Creation />} />
        </Routes>

    )
}

export default Artists