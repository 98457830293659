import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
interface Props extends PropsWithChildren {
    active?: boolean,
    mode: string,
    to: string,
    label: string,
    onClick?: any
}
const NavItem = ({ children, mode, active = false, to, label, onClick = () => { } }: Props) => {
    return (
        <Link to={to} onClick={onClick} className={`flex flex-col transition-all justify-center rounded-lg px-2 gap-y-1 h-[35px] ${mode === 'open' ? "min-w-[125px] " : "items-center"} ${active && mode === "open" ? "bg-white" : "text-white"}`}>
            <div className='flex gap-x-2'>
                {children}
                <div className={` text-sm ${mode == "open" ? "block" : "hidden"}`}>{label}</div>
            </div>
            {active && mode === "close" &&
                <div className="bg-white  w-[60%] h-[3px] rounded-sm " />
            }
        </Link>
    )
}

export default NavItem