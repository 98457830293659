import Icons from '@assets/icons'
import { Form } from '@components'
import Input from '@components/form/input'

interface Props {
    user: any,
    onHide: Function,
    onSend: Function
}
const Upgrade = ({ user, onHide, onSend }: Props) => {


    return (
        <div>
            <div className='bg-white p-5 overflow-y-scroll'>
                <div className='flex items-center justify-between'>
                    <div>ارتقا حساب کاربر</div>
                    <div onClick={() => { onHide() }}><Icons.Close color='#000' /></div>
                </div>
                <Form onSend={onSend}
                    formProps={{ className: 'flex flex-col mt-5' }}
                    buttonProps={{ children: "ثبت درخواست", className: 'self-center px-3 py-2 mt-3 text-white bg-primary rounded-lg' }}>
                    <Input.Defualt
                        inputProps={{ disabled: true, defaultValue: user.name }}
                        labelProps={{ children: "نام کاربر" }}
                    />
                    <input type="hidden" name='id' value={user.id} />
                    <input type="hidden" name='username' value={user.username} />
                    <Input.Select
                        url='/manager/artists/create?select=type'
                        name='type_id'
                        placeHolder='لطفا یک مورد را انتخاب کنید'
                        labelProps={{
                            children: "نوع فعالیت"
                        }}
                    />
                    <Input.Select
                        url='/manager/artists/create?select=category'
                        name='category_id'
                        placeHolder='لطفا یک مورد را انتخاب کنید'
                        labelProps={{
                            children: "دسته بندی"
                        }}
                    />
                </Form>
            </div>
        </div>
    )
}

export default Upgrade