import { Fragment, useEffect } from 'react'
import { AsyncDelete, CardHeader, Paginate, Table } from '@components/common'
import { Link, useLocation } from 'react-router-dom'
import Icons from '@assets/icons'
import { API } from '@hooks'
import { queryGenerate } from '@components/utils'
const List = () => {
    const location = useLocation();
    const { list, loading, paginate, index, destroy } = API.useApi();

    let changeablepaginate = new URLSearchParams(location.search).get('paginate');
    useEffect(() => {
        let querypaginate = new URLSearchParams(location.search).get('paginate');
        index(querypaginate ? querypaginate : '/manager/sliders');

    }, [changeablepaginate])

    return (
        <Fragment>
            <CardHeader label='لیست اسلایدرها' textButton='ایجاد اسلایدر جدید' toButton='/settings/sliders/add' />
            <div className='my-10'>
                <div className='mb-5 px-2 grid lg:grid-cols-4'>
                </div>
                <Table
                    loading={loading}
                    head={[
                        {
                            label: "تصویر"
                        },
                        {
                            label: "آدرس"
                        },
                        {
                            label: "صفحه"
                        },
                        {
                            label: "مدیریت"
                        },
                    ]}
                    rows={list || []}
                    renderItem={(slider: any, i: number) => {
                        return (
                            <tr className='text-center' key={i}>
                                <td>
                                    <div className='flex justify-center'>
                                        <img className='w-[50px] h-[50px] object-cover rounded-lg' src={slider.image || require('@images/no-photo.png')} alt={slider.alt} />
                                    </div>
                                </td>
                                <td>{slider.href}</td>
                                <td>{slider.category ? slider.category?.name : slider.page == 'home' ? 'صفحه اصلی' : slider.page}</td>
                                <td>
                                    <div className='flex justify-center items-center gap-x-5'>
                                        <AsyncDelete onDestroy={async () => await destroy('/manager/sliders', slider.id)} />
                                        <Link to={changeablepaginate ? '/settings/sliders/edit/' + slider.id + "?paginate=" + changeablepaginate : '/settings/sliders/edit/' + slider.id} className="cursor-pointer">
                                            <Icons.Edit />
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        )
                    }}
                />
            </div>
            <Paginate {...paginate} onChange={(link: string) => {
                index(queryGenerate(link));
            }} />
        </Fragment>
    )
}

export default List;