import { useLocation } from "react-router";
import { Link } from "react-router-dom";
interface Props {
  links: Array<any>;
  onChange: Function;
}
const classNameItem =
  "bg-white50 flex items-center justify-center rounded-lg w-[30px] h-[30px]";
const classNameItemActive = classNameItem + " bg-[#000!important] text-white";
const removeBaseUrl = (url: string) =>
  url.replace("http://api.khorasanmedia.ir/api", "");
const Paginate = ({ links, onChange }: Props) => {
  const { pathname } = useLocation();
  if (links.length <= 3) {
    return null;
  }

  return (
    <div className="flex my-5  gap-x-3 justify-center items-center">
      {links?.[0]?.url ? (
        <Link to={pathname + "?paginate=" + removeBaseUrl(links?.[0]?.url)}>
          <div
            onClick={() => onChange(removeBaseUrl(links?.[0]?.url))}
            className={classNameItem}
          >
            <svg
              className="rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="15"
              viewBox="0 0 8 15"
              fill="none"
            >
              <path
                d="M7.79325 0.206748C8.04386 0.457352 8.06664 0.849506 7.8616 1.12585L7.79325 1.20502L1.70447 7.29412L7.79325 13.3832C8.04386 13.6338 8.06664 14.026 7.8616 14.3023L7.79325 14.3815C7.54265 14.6321 7.15049 14.6549 6.87415 14.4498L6.79498 14.3815L0.206748 7.79325C-0.0438557 7.54265 -0.066638 7.15049 0.138402 6.87415L0.206748 6.79498L6.79498 0.206748C7.07065 -0.0689161 7.51759 -0.0689161 7.79325 0.206748Z"
                fill="black"
              />
            </svg>
          </div>
        </Link>
      ) : null}
      {links.slice(1, links.length - 1).map((link) => {
        return (
          <Link
            to={
              link.url
                ? pathname + "?paginate=" + removeBaseUrl(link.url)
                : pathname
            }
          >
            <div
              className={link.active ? classNameItemActive : classNameItem}
              onClick={() => {
                if (link.url) {
                  onChange(removeBaseUrl(link.url));
                }
              }}
            >
              {link.label}
            </div>
          </Link>
        );
      })}
      {links[links.length - 1]?.url ? (
        <Link
          to={
            pathname +
            "?paginate=" +
            removeBaseUrl(links[links.length - 1]?.url)
          }
        >
          <div
            onClick={() =>
              onChange(removeBaseUrl(links[links.length - 1]?.url))
            }
            className={classNameItem}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="15"
              viewBox="0 0 8 15"
              fill="none"
            >
              <path
                d="M7.79325 0.206748C8.04386 0.457352 8.06664 0.849506 7.8616 1.12585L7.79325 1.20502L1.70447 7.29412L7.79325 13.3832C8.04386 13.6338 8.06664 14.026 7.8616 14.3023L7.79325 14.3815C7.54265 14.6321 7.15049 14.6549 6.87415 14.4498L6.79498 14.3815L0.206748 7.79325C-0.0438557 7.54265 -0.066638 7.15049 0.138402 6.87415L0.206748 6.79498L6.79498 0.206748C7.07065 -0.0689161 7.51759 -0.0689161 7.79325 0.206748Z"
                fill="black"
              />
            </svg>
          </div>
        </Link>
      ) : null}
    </div>
  );
};

export default Paginate;
