import { Form } from "@components"
import { FormLoading } from "@components/common"
import AddView from "@components/form/addview"
import Input from "@components/form/input"
import { Settings } from "@hooks"
import { Fragment } from "react"

const Contact = () => {
    const { links, onSave, loading } = Settings.useContactUs();
    return (
        <Fragment>
            {loading ? <FormLoading /> :
                <div className="my-4">
                    <Form
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={onSave}>
                        <AddView
                            label='راه های ارتباطی'
                            defultValue={links}
                            render={(view: any, i: any) => {
                                return (
                                    <div key={Math.random()}>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                            <Input.Defualt
                                                inputProps={{
                                                    name: `name[${view.id || view}]`,
                                                    placeholder: "عنوان را وارد نمایید",
                                                    defaultValue: view?.name
                                                }}
                                                labelProps={{ children: "عنوان" }}
                                            />
                                            <Input.Defualt
                                                inputProps={{
                                                    name: `url[${view.id || view}]`,
                                                    placeholder: "آدرس را وارد نمایید",
                                                    defaultValue: view?.url
                                                }}
                                                labelProps={{ children: "آدرس" }}
                                            />
                                        </div>
                                        <Input.Preview
                                            labelProps={{ children: "انتخاب آیکون" }}
                                            inputProps={{ name: `icon[${view.id || view}]` }}
                                            defaultValue={view?.icon}
                                        />
                                    </div>
                                )
                            }
                            }
                        />
                    </Form>
                </div>
            }
        </Fragment>
    )
}


export default Contact