import { PropsWithChildren, useState } from 'react'
import Menu from './menu'
import Header from './header';
import Sidebar from './sidebar';
import { useAppContext } from '@context';

const Layout = (props: PropsWithChildren) => {

    const { sizing } = useAppContext();
    return (
        <div className={`pe-[15px] ps-[15px] ${sizing.ps}  transition-all  delay-0`}>
            <Header />
            <Sidebar />
            {props.children}
        </div>
    )
}
export { Menu };
export default Layout