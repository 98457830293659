import React, { Fragment, useEffect, useState } from 'react'
import NavItem from './navItem'
import { useAppContext, useAuthContext } from '@context'
import Icon from '@icons'
import { useReactPath } from '@components/utils'
const Sidebar = () => {
    const { sizing, toggleSide, } = useAppContext();
    const { logout } = useAuthContext();
    const path = useReactPath();
    return (
        <Fragment>
            <aside className={`fixed ${sizing.mode == 'close' ? "hidden" : "block"} z-[100] lg:block start-[15px] top-0 bg-black rounded-2xl my-[25px] transition-all  bottom-0 ${sizing.width}`}>
                <div className='flex flex-col items-center h-full justify-between py-10'>
                    <div className='text-white min-w-[125px] text-center font-bold'>خراسان <span className={` ${sizing.mode === 'close' ? "hidden" : "inline"}`}>مدیا</span></div>
                    <div className='flex flex-col  items-start gap-y-4'>
                        <NavItem label='داشبورد' mode={sizing.mode} to='/' active={path == '/'}>
                            <Icon.Home color={sizing.mode === "open" && path == '/' ? "black" : "white"} />
                        </NavItem>

                        <NavItem label=' دسته بندی ها' mode={sizing.mode} to='/categories' active={path.includes('/categories')}>
                            <Icon.Category color={sizing.mode === "open" && path.includes('/categories') ? "black" : "white"} />
                        </NavItem>
                        <NavItem label='آلبوم ها' mode={sizing.mode} to='/albums' active={path.includes('/albums')}>
                            <Icon.Album color={sizing.mode === "open" && path.includes('/albums') ? "black" : "white"} />
                        </NavItem>
                        <NavItem label='موزیک ها' mode={sizing.mode} to='/musics' active={path.includes('/musics')}>
                            <Icon.Song color={sizing.mode === "open" && path.includes('/musics') ? "black" : "white"} />
                        </NavItem>
                        <NavItem label='ویدئو ها' mode={sizing.mode} to='/videos' active={path.includes('/videos')}>
                            <Icon.Video color={sizing.mode === "open" && path.includes('/videos') ? "black" : "white"} />
                        </NavItem>

                        <NavItem label='کاربران' mode={sizing.mode} to='/users' active={path.includes('/users')}>
                            <Icon.User color={sizing.mode === "open" && path.includes('/users') ? "black" : "white"} />
                        </NavItem>

                        <NavItem label='هنرمندان' mode={sizing.mode} to='/artists' active={path.includes('/artists')}>
                            <Icon.Artist color={sizing.mode === "open" && path.includes('/artists') ? "black" : "white"} />
                        </NavItem>

                    </div>
                    <div className={`flex flex-col  ${sizing.mode == "close" ? "" : "min-w-[125px]"}  items-start gap-y-4`}>
                        <NavItem label='تنظیمات' mode={sizing.mode} to='/settings' active={path.includes('/settings')}>
                            <Icon.Setting color={sizing.mode === "open" && path.includes('/settings') ? "black" : "white"} />
                        </NavItem>
                        <NavItem label='خروج از حساب' mode={sizing.mode} to='/' onClick={logout} active={false}>
                            <Icon.Logout color={"white"} />
                        </NavItem>
                        <div onClick={() => { toggleSide() }} className='flex items-center px-2 gap-x-2 transition-all h-[30px]'>
                            <div className={sizing.mode == "open" ? 'rotate-180' : ""}>
                                <Icon.Arrow />
                            </div>
                            <div className={`text-white text-sm ${sizing.mode == "open" ? "block" : "hidden"} `}>بستن منو</div>
                        </div>
                    </div>

                </div>
            </aside>
            {sizing.mode === "open" && <div onClick={() => { toggleSide() }} className='fixed lg:hidden top-0 left-0 z-[99] w-full h-full bg-black opacity-[0.5]'></div>}
        </Fragment>
    )
}

export default Sidebar