import { Fragment, useEffect, useState } from 'react'
import { AsyncDelete, CardHeader, Paginate, SearchBox, Table } from '@components/common'
import { Link, useLocation } from 'react-router-dom'
import Icons from '@assets/icons'
import { API } from '@hooks'
import { queryGenerate } from '@components/utils'
const List = () => {
    const { list, loading, paginate, index, destroy } = API.useApi();
    const { search } = useLocation();
    const [query, setQuery] = useState<string>()
    const [text, setText] = useState<string>('')
    useEffect(() => {
        let new_week = new URLSearchParams(search).get('new_week');
        let newQuery = "";

        if (text.length) {
            newQuery += "?text=" + text;
        }

        if (new_week) {
            newQuery += "?new_week=1";
        }
        setQuery(newQuery)
    }, [search, text])

    let changeablepaginate = new URLSearchParams(search).get('paginate');
    useEffect(() => {
        let querypaginate = new URLSearchParams(search).get('paginate');
        const timeOutId = setTimeout(() => index(queryGenerate(querypaginate ? querypaginate + query : '/manager/artists' + query)), 100);
        return () => clearTimeout(timeOutId);

    }, [query, changeablepaginate])

    return (
        <Fragment>
            <CardHeader label='لیست هنرمندان' textButton='ایجاد هنرمند جدید' toButton='/artists/add' />
            <div className='my-10'>
                <div className='mb-5 px-2 grid lg:grid-cols-4'>
                    <SearchBox onChange={(value: string) => {
                        setText(value)
                    }} />
                </div>
                <Table
                    loading={loading}
                    head={[
                        {
                            label: "تصویر"
                        },
                        {
                            label: "نام هنرمند"
                        },
                        {
                            label: "بخش فعالیت"
                        },

                        {
                            label: "مدیریت"
                        },
                    ]}
                    rows={list || []}
                    renderItem={(artist: any, i: number) => {
                        return (
                            <tr className='text-center' key={i}>
                                <td>
                                    <div className='flex justify-center'>
                                        <img className='w-[50px] h-[50px] object-cover rounded-lg' src={artist.userinfo?.profile || require('@images/no-photo.png')} alt={artist.user?.name} />
                                    </div>
                                </td>
                                <td>{artist.userinfo?.name}</td>
                                <td>{artist.type?.name}</td>
                                <td>
                                    <div className='flex justify-center items-center gap-x-5'>
                                        <AsyncDelete onDestroy={async () => await destroy('/manager/artists', artist.id)} />
                                        <Link to={changeablepaginate ? '/artists/edit/' + artist.id + "?paginate=" + changeablepaginate : '/artists/edit/' + artist.id}>
                                            <Icons.Edit />
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        )
                    }}
                />
            </div>
            <Paginate {...paginate} onChange={(link: string) => {
                index(queryGenerate(link + query));
            }} />
        </Fragment >
    )
}

export default List;