import { DropDown } from "@components";
import Icons from "@icons";
import CardState from "./card-state";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Calendar from "./calendar";
import CardUser from "./card-user";
import { moment, toJalali } from "@components/utils";
import { API } from "@hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DashboardLoading } from "@components/common";
function Home() {
    const { find, item, loading } = API.useApi()

    useEffect(() => {
        find('/manager/dashboard')
    }, [])
    const navigate = useNavigate();
    const [chartData, setChartData] = useState<any>([])
    useEffect(() => {
        if (item) {
            setChartData(item.chartvisit)
        }
    }, [item])
    return (
        <div className="grid grid-cols-1 xl:grid-cols-4 px-[15px] gap-5">
            {loading ?
                <DashboardLoading />
                :
                <>
                    <div className="flex flex-col gap-y-4 xl:col-start-1 xl:col-end-4 ">
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-5">
                            <CardState value={item?.weekusers} unit="کاربر" details="کاربران جدید هفته">
                                <Icons.UserPlus />
                                <DropDown menus={[
                                    {
                                        label: "مشاهده همه",
                                        onClick: () => {
                                            navigate('/users?new_week=1', { replace: true })
                                        }
                                    }
                                ]} />
                            </CardState>
                            <CardState value={item?.artists} unit="هنرمند" details="هنرمندان">
                                <Icons.Profile />
                                <DropDown menus={[
                                    {
                                        label: "مشاهده همه",
                                        onClick: () => {
                                            navigate('/artists', { replace: true })
                                        }
                                    }
                                ]} />
                            </CardState>
                            <CardState value={item?.users} unit="کاربر" details="همه کاربران">
                                <Icons.Users />
                                <DropDown menus={[
                                    {
                                        label: "مشاهده همه",
                                        onClick: () => {
                                            navigate('/users', { replace: true })
                                        }
                                    }
                                ]} />
                            </CardState>
                            <CardState value={item?.weeknews} unit="محتوا" details="جدید های آپلود شده">
                                <Icons.PaperPlus />
                                <DropDown menus={[
                                    {
                                        label: "مشاهده همه",
                                        onClick: () => {
                                            navigate('/musics/new', { replace: true })
                                        }
                                    }
                                ]} />
                            </CardState>
                            <CardState value={item?.views} unit="بازدید از سایت" details="سی روز گذشته">
                                <Icons.Show />
                            </CardState>
                        </div>
                        <div className="card">
                            <div className="flex items-center mt-3 mb-5 mx-3 justify-between">
                                <h3 className="font-bold text-lg">بازدید از سایت</h3>
                                <h5 className="font-bold text-sm">سی روز گذشته</h5>
                            </div>
                            <div className="h-[45vh] w-[105%]">
                                <ResponsiveContainer>
                                    <LineChart data={[...chartData].map((item) => {
                                        return {
                                            name: toJalali(item.date, 'jDD jMMMM'),
                                            count: item.count
                                        }
                                    }).reverse()}>
                                        <YAxis axisLine={false} tickLine={false} fontSize={0} />
                                        <XAxis dataKey='name' fontSize={13} tickLine={false} domain={[0, 3]} axisLine={false} />
                                        <Tooltip content={({ active, payload }) => {
                                            if (active && payload && payload.length) {
                                                return <div>{`${payload[0].payload.name} : ${payload[0].payload.count} بازدید`}</div>
                                            }
                                        }} />
                                        <CartesianGrid vertical={false} stroke="#DCDCE0" />
                                        <Line animationDuration={5000} type="monotone" dataKey="count" stroke="#E292FC" strokeWidth={5} dot={false} strokeLinejoin="round" strokeLinecap='round' />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-4  xl:col-start-4 xl:col-end-5 self-start ">
                        <div className="card">
                            <Calendar />
                        </div>
                        <div className="card relative overflow-hidden mb-4">
                            <div className="flex justify-between items-center">
                                <h3 className="font-semibold"> کاربران جدید</h3>
                                <DropDown menus={[
                                    {
                                        label: "مشاهده همه",
                                        onClick: () => {
                                            navigate('/users', { replace: true })
                                        }
                                    }
                                ]} />

                            </div>
                            <div className={`flex flex-col ${item?.newusers && item?.newusers.length ? "" : "justify-center"} h-[40vh] overflow-hidden card-limit`}>
                                {item?.newusers && item?.newusers.length ? item?.newusers.map((user: any, i: number) => {
                                    return <CardUser key={i} name={user?.name} image={user?.profile} onClick={() => { navigate('/users/edit/' + user.id, { replace: true }) }} time={String(moment(new Date(user?.updated_at)).calendar())} />
                                }) :
                                    <div className="text-center">
                                        هنوز کاربری ثبت نشده
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default Home