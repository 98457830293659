import { Form, Input } from '@components'
import { CardHeader, FormLoading } from '@components/common'
import AddView from '@components/form/addview'
import { API } from '@hooks'
import { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

const Creation = () => {
    const navigate = useNavigate()
    const location = useLocation();
    let paginate = new URLSearchParams(location.search).get('paginate');
    const { item, find, setItem, loading, setLoading, update, store, progress } = API.useApi();
    const { id } = useParams();
    const [views, setviews] = useState([1])
    useEffect(() => {
        setLoading(true)
        if (id) {
            find('/manager/albums', id)
        } else {
            setItem(null);
            setTimeout(() => {
                setLoading(false);
            }, 10);
        }
    }, [id])

    return (
        <Fragment>
            <CardHeader label={id ? 'ویرایش آلبوم' : 'ایجاد آلبوم جدید'} textButton='لیست آلبوم ها' toButton='/albums' />
            {loading ? <FormLoading /> :
                <div className='my-10 '>
                    <Form
                        progress={progress}
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={async (data: FormData) => {
                            if (id) {
                                data.append('_method', 'PUT');
                                await update('manager/albums', data, id);
                                if (paginate) {
                                    navigate(`/albums?paginate=${paginate}`)
                                } else {
                                    navigate(`/albums`)
                                }
                            } else {
                                await store('manager/albums', data);
                                if (paginate) {
                                    navigate(`/albums?paginate=${paginate}`)
                                } else {
                                    navigate(`/albums`)
                                }
                            }
                        }}>
                        <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-5'>
                            <Input.Defualt
                                inputProps={{ name: 'name', placeholder: "متن را وارد نمایید", defaultValue: item?.name }}
                                labelProps={{ children: "نام آلبوم" }}
                            />
                            <Input.Defualt
                                inputProps={{ name: 'slug', placeholder: "متن را وارد نمایید", defaultValue: item?.slug }}
                                labelProps={{ children: "آدرس URL" }}
                            />

                            <Input.Time name='publish' disableDayPicker={false} defaultValue={item?.musics?.[0]?.publish} labelProps={{
                                children: "زمان انتشار"
                            }} />

                            <Input.Select
                                url='/manager/albums/create?select=artist'
                                name='artist_id'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: " هنرمند آلبوم"
                                }}
                                defaultValue={item?.artist_id}
                            />
                            <Input.Select
                                url='/manager/albums/create?select=category '
                                name='category_id'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "دسته بندی آلبوم"
                                }}
                                defaultValue={item?.category_id}
                            />
                            <Input.Defualt
                                inputProps={{ name: "seo[title]", placeholder: "متن را وارد نمایید", defaultValue: item?.seo?.title }}
                                labelProps={{ children: "عنوان صفحه" }}
                            />

                            <Input.Defualt
                                inputProps={{ name: "info[release_year]", placeholder: "متن را وارد نمایید", defaultValue: item?.seo?.description }}
                                labelProps={{ children: "سال انتشار " }}
                            />
                            <Input.Select
                                options={[{
                                    label: "غیر فعال",
                                    value: 0
                                },
                                {
                                    label: "فعال",
                                    value: 1
                                }]}
                                name='status'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "وضعیت آلبوم"
                                }}
                                defaultValue={item?.status || 1}
                            />
                            <Input.Select
                                options={[{
                                    label: "نمایش داده نشود",
                                    value: 0
                                },
                                {
                                    label: "نمایش داده شود",
                                    value: 1
                                }]}
                                name='home_visibility'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "نمایش در صفحه اصلی"
                                }}
                                defaultValue={item?.home_visibility || 1}
                            />
                            <Input.Defualt
                                inputProps={{ name: "alt", placeholder: "متن را وارد نمایید", defaultValue: item?.alt }}
                                labelProps={{ children: "آلت عکس کاور" }}
                            />
                        </div>
                        <div className='my-5'>
                            <Input.Textarea
                                inputProps={{ name: "seo[schema]", style: { direction: "ltr", textAlign: "left" }, placeholder: "schema code  ...", defaultValue: item?.seo?.schema }}
                                labelProps={{ children: "اسکیما " }}
                            />

                        </div>
                        <div className='my-5'>
                            <Input.Textarea
                                inputProps={{ name: "seo[description]", placeholder: "متن را وارد نمایید", defaultValue: item?.seo?.description }}
                                labelProps={{ children: "توضیحات سئو " }}
                            />
                        </div>
                        <div className='my-5'>
                            <Input.Preview
                                defaultValue={item?.cover}
                                labelProps={{ children: "تصویر آلبوم" }}
                                inputProps={{ name: "cover" }}
                            />
                        </div>
                        <div className="">
                            <AddView defultValue={item?.musics ? item?.musics : views} onAddView={(newViews) => { setviews(newViews) }} label='افزودن موزیک به البوم' render={(view: any, i: any) => {
                                return (

                                    <div key={Math.random()} className='grid md:grid-cols-2 lg:grid-cols-3 gap-x-5'>
                                        <input type="hidden" name={`musics[${i}][id]`} value={view?.id} />

                                        <Input.Defualt
                                            inputProps={{ name: `musics[${i}][name]`, placeholder: "متن را وارد نمایید", defaultValue: view?.name }}
                                            labelProps={{ children: "نام موزیک" }}
                                        />
                                        <Input.Defualt
                                            inputProps={{ name: `musics[${i}][slug]`, placeholder: "متن را وارد نمایید", defaultValue: view?.slug }}
                                            labelProps={{ children: "ادرس url" }}
                                        />

                                        <Input.File
                                            inputProps={{
                                                name: `musics[${i}][urls][normal]`,
                                            }}
                                            fileDuration={{
                                                inputProps: {
                                                    name: `musics[${i}][duration]`,
                                                    defaultValue: view?.duration
                                                }
                                            }}
                                            labelProps={{ children: "فایل 128" }}
                                            placeholder="فایل با کیفیت معمولی را انتخاب کنید"
                                        />
                                        <Input.File
                                            inputProps={{ name: `musics[${i}][urls][hq]` }}
                                            fileDuration={{
                                                inputProps: {
                                                    name: `musics[${i}][duration]`,
                                                    defaultValue: view?.duration
                                                }
                                            }}
                                            labelProps={{ children: "فایل 320" }}
                                            placeholder="فایل 320 را انتخاب کنید"
                                        />
                                        <Input.Defualt
                                            inputProps={{ name: `musics[${i}][info][arranger]`, placeholder: "متن را وارد نمایید", defaultValue: view?.info?.arranger }}
                                            labelProps={{ children: "تنظیم کننده" }}
                                        />
                                        <Input.Defualt
                                            inputProps={{ name: `musics[${i}][info][composer]`, placeholder: "متن را وارد نمایید", defaultValue: view?.info?.composer }}
                                            labelProps={{ children: "نام اهنگساز" }}
                                        />
                                        <Input.Defualt
                                            inputProps={{ name: `musics[${i}][info][songwriter]`, placeholder: "متن را وارد نمایید", defaultValue: view?.info?.songwriter }}
                                            labelProps={{ children: "نام ترانه سرا" }}
                                        />

                                    </div>
                                )
                            }} />
                        </div>
                    </Form>

                </div>
            }
        </Fragment>
    )
}

export default Creation