import React from 'react'

const DashboardLoading = () => {
    return (
        <>
            <div className=" animate-pulse flex flex-col gap-y-4 xl:col-start-1 xl:col-end-4 ">
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-5">
                    <div className="card">
                        <div className='bg-white100 rounded-lg h-[150px] w-full'></div>
                    </div>
                    <div className="card">
                        <div className='bg-white100 rounded-lg h-[150px] w-full'></div>
                    </div>
                    <div className="card">
                        <div className='bg-white100 rounded-lg h-[150px] w-full'></div>
                    </div>
                    <div className="card">
                        <div className='bg-white100 rounded-lg h-[150px] w-full'></div>
                    </div>
                    <div className="card">
                        <div className='bg-white100 rounded-lg h-[150px] w-full'></div>
                    </div>
                </div>
                <div className="card">
                    <div className="flex items-center mt-3 mb-5 mx-3 justify-between">

                    </div>
                    <div className="h-[45vh] w-full">
                        <div className='bg-white100 rounded-lg my-2 h-5 w-[30%]'></div>
                        <div className='bg-white100 rounded-lg h-full w-full'></div>
                    </div>
                </div>
            </div>
            <div className="flex animate-pulse flex-col gap-y-4  xl:col-start-4 xl:col-end-5 self-start ">
                <div className="card">
                    <div className='bg-white100 rounded-lg h-[200px] w-full'></div>
                </div>
                <div className="card relative overflow-hidden mb-4">
                    <div className="flex justify-between items-center">
                        <div className='bg-white100 rounded-lg h-5 mb-4 w-full'></div>
                    </div>
                    <div className="flex flex-col h-[40vh] gap-4 overflow-hidden card-limit">
                        <div className='bg-white100 rounded-lg h-12 w-full'></div>
                        <div className='bg-white100 rounded-lg h-12 w-full'></div>
                        <div className='bg-white100 rounded-lg h-12 w-full'></div>
                        <div className='bg-white100 rounded-lg h-12 w-full'></div>
                        <div className='bg-white100 rounded-lg h-12 w-full'></div>
                        <div className='bg-white100 rounded-lg h-12 w-full'></div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default DashboardLoading