import { Fragment, useEffect } from 'react'
import { AsyncDelete, CardHeader, Paginate, Table } from '@components/common'
import { Link } from 'react-router-dom'
import Icons from '@assets/icons'
import { API } from '@hooks'
import { queryGenerate } from '@components/utils'
const List = () => {
    const { list, loading, paginate, index, destroy } = API.useApi();

    const query = "?newupload=true";


    useEffect(() => {
        index(queryGenerate('/manager/musics' + query));
    }, [])

    return (
        <Fragment>
            <CardHeader label="لیست محتوا جدید" />
            <div className='my-10'>
                <Table loading={loading} head={
                    [
                        {
                            label: "تصویر"
                        },
                        {
                            label: "نام موزیک"
                        },
                        {
                            label: "نام خواننده"
                        },
                        {
                            label: "دسته بندی"
                        },
                        {
                            label: "نوع محتوا"
                        },
                        {
                            label: "مدیریت"
                        },
                    ]}
                    rows={list || []}
                    renderItem={(music: any, i: number) => {
                        return (
                            <tr className='text-center' key={i}>
                                <td>
                                    <div className='flex justify-center'>
                                        <img className='w-[50px] h-[50px] object-cover rounded-lg' src={music?.cover || require('@images/no-photo.png')} alt={music.name} />
                                    </div>
                                </td>
                                <td>{music?.name}</td>
                                <td>{music?.artistinfo?.userinfo?.name}</td>
                                <td>{music?.category?.name}</td>
                                <td>{music.type == 'video' ? "موزیک ویدئو" : "موزیک"}</td>
                                <td>
                                    <div className='flex justify-center items-center gap-x-5'>
                                        <AsyncDelete onDestroy={async () => await destroy('/manager/musics', music.id)} />
                                        <Link to={music.type == 'video' ? '/musics/music-videos/edit/' + music.id + '?type=video' : '/musics/edit/' + music.id}>
                                            <Icons.Edit />
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        )
                    }}
                />
            </div>
            <Paginate {...paginate} onChange={(link: string) => {
                index(queryGenerate(link + query));
            }} />
        </Fragment >
    )
}

export default List;