import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { LabelHTMLAttributes, useState } from "react";
interface Props {
    containerClassName?: string,
    labelProps: LabelHTMLAttributes<HTMLLabelElement>,
    name: string,
    placeholder?: string,
    defaultValue?: any

}
const InputTime = ({ containerClassName, labelProps, name, placeholder, defaultValue }: Props) => {

    const [value, setValue] = useState<string>();
    if (!defaultValue) {
        defaultValue = new Date()
    }
    return (
        <div className={'flex flex-col w-full ' + containerClassName}>
            <label className="text-sm font-medium md:text-md" {...labelProps} />
            <input type="hidden" name={name} value={value} />
            <DatePicker
                calendar={persian}
                locale={persian_fa}
                calendarPosition="bottom-right"
                value={new Date(defaultValue).toLocaleDateString('fa')}
                placeholder={placeholder}
                onChange={(e: any) => {
                    const date = new Date(e);
                    const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
                    setValue(`${year}-${month + 1}-${day}`)
                }}
            />
        </div>
    )
}

export default InputTime;