import React, { Fragment, HtmlHTMLAttributes, InputHTMLAttributes, LabelHTMLAttributes, PropsWithChildren, useState } from 'react'
interface Props extends PropsWithChildren {
    inputProps?: InputHTMLAttributes<HTMLInputElement>
    labelProps?: LabelHTMLAttributes<HTMLLabelElement>
    containerProps?: HtmlHTMLAttributes<HTMLDivElement>
    containerClassName?: string,
    defaultValue?: string
}
const Preview = (props: Props) => {

    const [file, setfile] = useState<File | null>()
    return (
        <div className={'flex flex-col w-full relative ' + props.containerClassName}>
            <label className="text-sm font-medium md:text-md" {...props.labelProps} />
            <input accept='image/*' onChange={({ target: { files } }) => {
                if (files?.length) {
                    setfile(files[0])
                } else {
                    setfile(null)
                }
            }} className='opacity-0 absolute w-full h-full left-0 top-0' {...props.inputProps} type='file' />
            <div className='border-[2px] my-3 border-white100 flex flex-col gap-4 items-center justify-center border-dashed p-4 rounded-lg' {...props.containerProps}>
                {file || props.defaultValue ?
                    <img src={file ? URL.createObjectURL(file) : props.defaultValue} className='w-full max-h-[150px] object-contain' />
                    :
                    <Fragment>
                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.4171 0C43.8488 0 47.6715 1.48 50.4689 4.28291C53.3931 7.20984 54.9971 11.4679 54.9916 16.2745L54.9956 35.557C55.0004 35.6311 55.0013 35.7055 54.9982 35.7798L54.9916 38.7226C54.9916 48.4542 48.7271 54.9943 39.4033 54.9943H15.5745C6.259 54.9943 0 48.4542 0 38.7226V16.2717C0 6.54012 6.259 0 15.5745 0H39.4171ZM40.1794 29.8544C38.5589 29.8544 36.6572 32.5361 34.9732 34.9035C32.6637 38.1557 30.2742 41.5208 26.6748 41.5208C24.2412 41.5208 22.0115 40.5837 20.0464 39.7542C16.9211 38.4368 15.5045 38.0151 14.1182 39.1561C11.3431 41.4429 8.60105 45.607 7.3411 47.6562C9.32973 49.7065 12.1546 50.8602 15.5745 50.8602H39.4033C46.3624 50.8602 50.8575 46.0978 50.8575 38.7226L50.8575 36.5205L50.7939 36.4535C48.3812 33.9678 44.215 29.8544 40.1794 29.8544ZM39.4171 4.13408H15.5745C8.62645 4.13408 4.13408 8.8993 4.13408 16.2717V38.7226C4.13408 40.5652 4.41451 42.2448 4.94359 43.7277C6.53849 41.3197 8.94724 38.0594 11.4916 35.9646C14.9422 33.1259 18.6574 34.6858 21.6505 35.9453C23.4116 36.6867 25.0762 37.3867 26.6748 37.3867C28.141 37.3867 29.9793 34.796 31.6053 32.5113C33.8626 29.3253 36.4257 25.7203 40.1794 25.7203C44.299 25.7203 48.0301 28.2519 50.8604 30.7643L50.8575 16.2717C50.863 12.5152 49.7165 9.37886 47.5447 7.20433C45.5383 5.19516 42.7299 4.13408 39.4171 4.13408ZM18.8983 11.5465C22.8422 11.5492 26.0502 14.7573 26.053 18.6985C26.053 22.6424 22.8449 25.8532 18.8983 25.8532C14.9516 25.8532 11.7436 22.6451 11.7436 18.7012C11.7436 14.7545 14.9516 11.5465 18.8983 11.5465ZM18.8955 15.6806C17.2309 15.6806 15.8776 17.0338 15.8776 18.7012C15.8776 20.3659 17.2309 21.7191 18.8983 21.7191C20.5629 21.7191 21.9189 20.3659 21.9189 18.704C21.9162 17.0366 20.5602 15.6833 18.8955 15.6806Z" fill="#DCDCE0" />
                        </svg>
                        <div className='text-white100'>تصویر مورد نظر را انتخاب نمایید</div>
                    </Fragment>
                }
            </div>

        </div>
    )
}

export default Preview