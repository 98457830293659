
import { Menu } from '@components/layout'
import { Route, Routes } from 'react-router'
import About from './about'
import Contact from './contact'
import Maininfo from './maininfo'
import Rules from './rules'
import HeaderSetting from './header'
import HomeSetting from './home'
import 'suneditor/dist/css/suneditor.min.css';
import { Sliders } from '@views'
import Profile from './profile'
import Apps from './apps'
const Settings = () => {

    return (
        <Menu menus={[
            {
                label: 'اطلاعات اصلی',
                to: '/settings'
            },
            {
                label: 'لینک های دانلود',
                to: '/settings/apps'
            },
            {
                label: 'شرایط و قوانین',
                to: '/settings/rules'
            },
            {
                label: 'درباره ما',
                to: '/settings/about'
            },
            {
                label: 'تماس با ما',
                to: '/settings/contact'
            },
            {
                label: 'تنظیمات هدر سایت',
                to: '/settings/headerSetting'
            },
            {
                label: 'تنظیمات صفحه اصلی',
                to: '/settings/homeSectionSetting'
            },
            {
                label: 'لیست اسلایدر',
                to: '/settings/sliders',
                type: "list"
            },
            {
                label: 'ایجاد اسلایدر جدید',
                to: '/settings/sliders/add'
            }
        ]}>
            <Routes>
                <Route path='/' element={<Maininfo />} />
                <Route path='/apps' element={<Apps />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/rules' element={<Rules />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/about' element={<About />} />
                <Route path='/sliders/*' element={<Sliders />} />
                <Route path='/headerSetting' element={<HeaderSetting />} />
                <Route path='/homeSectionSetting' element={<HomeSetting />} />
            </Routes>
        </Menu>
    )
}

export default Settings