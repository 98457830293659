import { Form } from "@components";
import { CardHeader, FormLoading } from "@components/common"
import Input from "@components/form/input";
import { Settings } from "@hooks";
import { Fragment } from "react"

const Maininfo = () => {
    const { settings, onSave, loading } = Settings.useSettings();
    return (
        <Fragment>
            <CardHeader label='اطلاعات اصلی' />
            {loading ? <FormLoading /> :
                <div className='my-10 '>
                    <Form
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={onSave}>
                        <div className='grid grid-cols-1  md:grid-cols-2 gap-5'>
                            <Input.Defualt
                                inputProps={{ name: 'name', placeholder: "نام سایت را وارد نمایید", defaultValue: settings?.name }}
                                labelProps={{ children: "نام سایت" }}
                            />
                            <Input.Defualt
                                inputProps={{ name: 'seo[title]', placeholder: "عنوان  را وارد نمایید", defaultValue: settings?.seo?.title }}
                                labelProps={{ children: "عنوان" }}
                            />
                        </div>
                        <div className='my-5'>
                            <Input.Textarea
                                inputProps={{ name: "seo[schema]", style: { direction: "ltr", textAlign: "left" }, placeholder: "schema code  ...", defaultValue: settings?.seo?.schema }}
                                labelProps={{ children: "اسکیما " }}
                            />

                        </div>
                        <div className='my-5'>
                            <Input.Textarea
                                inputProps={{ name: "seo[description]", placeholder: "متن را وارد نمایید", defaultValue: settings?.seo?.description }}
                                labelProps={{ children: "توضیحات سئو " }}
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mb-5'>
                            <Input.Preview
                                containerProps={{ style: { background: "#444" } }}
                                labelProps={{ children: "لوگو در حالت تاریک" }}
                                inputProps={{ name: "logo_dark" }}
                                defaultValue={settings?.logo_dark}
                            />
                            <Input.Preview
                                labelProps={{ children: "لوگو در حالت روشن" }}
                                inputProps={{ name: "logo_light" }}
                                defaultValue={settings?.logo_light}
                            />
                        </div>

                    </Form>
                </div>}
        </Fragment >
    )
}
export default Maininfo