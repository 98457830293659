import { Form } from "@components";
import { CardHeader, FormLoading } from "@components/common"
import { Settings } from "@hooks";
import { Fragment, useEffect, useState } from "react"
import SunEditor from "suneditor-react";
const Rules = () => {
    const { settings, onSave, loading } = Settings.useSettings();
    const [text, setText] = useState<string>(settings?.rulls);
    useEffect(() => {
        setText(settings?.rulls)
    }, [settings])

    return (
        <Fragment>
            <CardHeader label='شرایط و قوانین' />
            {loading ? <FormLoading /> :
                <div className='my-10 '>
                    <Form
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={onSave}>
                        <input type="hidden" name="rulls" defaultValue={text} />
                        <SunEditor setContents={text}
                            setDefaultStyle="min-height:250px; font-family: vazir; font-size: 15px;" placeholder="شرایط و قوانین سایت را وارد نمایید"
                            onChange={(value: string) => {
                                setText(value);
                            }} />
                    </Form>
                </div>
            }
        </Fragment>
    )
}

export default Rules