
import { Form, Input } from '@components'
import { useLogin } from '@hooks'
const Login = () => {
    const { onLogin } = useLogin();

    return (
        <div className='grid  md:grid-cols-6  h-[100vh]'>
            <div className='py-6 px-5 md:col-start-2 md:col-end-6 lg:col-start-3 lg:col-end-5 md:self-center  bg-white md:rounded-2xl flex flex-col justify-center items-center'>
                <div className='w-full h-[10vh]'>
                    <img src={require('@images/logo.png')} alt="خراسان مدیا" className='w-[100%] h-[100%] object-contain' />
                </div>
                <h1 className='my-8 text-xl font-medium'>ورود به حساب مدیریت</h1>
                <Form
                    buttonProps={{
                        className: 'w-full px-3 py-3 mt-3 text-white bg-primary rounded-lg',
                        children: "ارسال اطلاعات"
                    }}
                    onSend={onLogin}>
                        <input type="hidden" name='role' value='admin' />
                    <Input.Defualt
                        containerClassName='my-2'
                        labelProps={{ htmlFor: "username", children: 'نام کاربری' }}
                        inputProps={{ id: "username", name: "username", placeholder: "نام کاربری را وارد نمایید" }}
                    />

                    <Input.Password
                        containerClassName='my-2'
                        labelProps={{ htmlFor: "password", children: 'کلمه عبور' }}
                        inputProps={{ id: "password", name: "password", placeholder: "کلمه عبور را وارد نمایید" }}
                    />

                </Form>
            </div>
        </div>
    )
}

export default Login