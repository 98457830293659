import { PropsWithChildren, createContext, useContext, useState } from 'react'


interface TypeSize {
    ps: string,
    width: string,
    mode: string
}


interface ProviderValue {
    sizing: TypeSize
    toggleSide: Function,
    pageTitle: string,
    setPageTitle: Function,
}
const Context = createContext<ProviderValue | null>(null);
const initialSizing = {
    ps: 'lg:ps-[100px]',
    width: 'w-[150px] lg:w-[65px]',
    mode: 'close'
}
const AppContext = (props: PropsWithChildren) => {




    const [sizing, setSizing] = useState<TypeSize>(initialSizing);


    const [pageTitle, setPageTitle] = useState<string>('وقت بخیر مدیر')

    const toggleSide = () => {
        if (sizing.mode == 'close') {
            setSizing({
                ps: 'lg:ps-[185px]',
                width: 'w-[150px] lg:w-[150px]',
                mode: 'open'
            })
        } else {
            setSizing(initialSizing)
        }
    }

    return (
        <Context.Provider value={{ toggleSide, sizing, setPageTitle, pageTitle }}>
            {props.children}
        </Context.Provider>
    )
}

export default AppContext

export function useAppContext(): ProviderValue {
    return useContext(Context) || { sizing: initialSizing, toggleSide: () => { }, pageTitle: "", setPageTitle: () => { } };
} 