import { Form, Input } from "@components";
import { CardHeader, FormLoading } from "@components/common";
import { API } from "@hooks";
import { Fragment, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

const Creation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item, find, setItem, loading, setLoading, update, store } =
    API.useApi();
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    if (id) {
      find("/manager/categories", id);
    } else {
      setItem(null);
      setTimeout(() => {
        setLoading(false);
      }, 10);
    }
  }, [id]);

  let paginate = new URLSearchParams(location.search).get("paginate");

  return (
    <Fragment>
      <CardHeader
        label={id ? "ویرایش دسته بندی" : "ایجاد دسته بندی جدید"}
        textButton="لیست دسته بندی ها"
        toButton="/categories"
      />
      {loading ? (
        <FormLoading />
      ) : (
        <div className="my-10 ">
          <Form
            formProps={{ className: "flex flex-col" }}
            buttonProps={{
              children: "ثبت اطلاعات",
              className:
                "self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg",
            }}
            onSend={async (data: FormData) => {
              if (id) {
                data.append("_method", "PUT");
                await update("manager/categories", data, id);
                if (paginate) {
                  navigate(`/categories?paginate=${paginate}`);
                } else {
                  navigate(`/categories`);
                }
              } else {
                await store("manager/categories", data);
                if (paginate) {
                  navigate(`/categories?paginate=${paginate}`);
                } else {
                  navigate(`/categories`);
                }
              }
            }}
          >
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-5">
              <Input.Defualt
                inputProps={{
                  name: "name",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.name,
                }}
                labelProps={{ children: "نام دسته بندی" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "slug",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.slug,
                }}
                labelProps={{ children: "آدرس URL" }}
              />
              <Input.Select
                url="/manager/categories/create?select=part"
                name="part_id"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: " بخش دسته بندی",
                }}
                defaultValue={item?.part_id}
              />

              <Input.Defualt
                inputProps={{
                  name: "alt",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.category_data?.alt,
                }}
                labelProps={{ children: "آلت عکس کاور" }}
              />
              <Input.Select
                options={[
                  {
                    label: "غیر فعال",
                    value: 0,
                  },
                  {
                    label: "فعال",
                    value: 1,
                  },
                ]}
                name="status"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: "وضعیت دسته بندی",
                }}
                defaultValue={item?.status}
              />

              <Input.Defualt
                inputProps={{
                  name: "seo[title]",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.seo?.title,
                }}
                labelProps={{ children: "عنوان صفحه" }}
              />
            </div>
            <div className="my-5">
              <Input.Textarea
                inputProps={{
                  name: "seo[schema]",
                  style: { direction: "ltr", textAlign: "left" },
                  placeholder: "schema code  ...",
                  defaultValue: item?.seo?.schema,
                }}
                labelProps={{ children: "اسکیما " }}
              />
            </div>
            <div className="my-5">
              <Input.Textarea
                inputProps={{
                  name: "seo[description]",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.seo?.description,
                }}
                labelProps={{ children: "توضیحات سئو " }}
              />
            </div>
            <div className="my-5">
              <Input.Preview
                defaultValue={item?.category_data?.cover}
                labelProps={{ children: "تصویر دسته بندی" }}
                inputProps={{ name: "cover" }}
              />
            </div>
          </Form>
        </div>
      )}
    </Fragment>
  );
};

export default Creation;
