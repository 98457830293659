import { Form, Input } from "@components";
import { CardHeader, FormLoading } from "@components/common";
import { API } from "@hooks";
import { Fragment, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

const Creation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let paginate = new URLSearchParams(location.search).get("paginate");
  const { item, find, setItem, loading, setLoading, update, store, progress } =
    API.useApi();
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    if (id) {
      find("/manager/videos", id);
    } else {
      setItem(null);
      setTimeout(() => {
        setLoading(false);
      }, 10);
    }
  }, [id]);

  return (
    <Fragment>
      <CardHeader
        label={id ? "ویرایش ویدئو" : "ایجاد ویدئو جدید"}
        textButton="لیست ویدئو ها"
        toButton="/videos"
      />
      {loading ? (
        <FormLoading />
      ) : (
        <div className="my-10 ">
          <Form
            progress={progress}
            formProps={{ className: "flex flex-col" }}
            buttonProps={{
              children: "ثبت اطلاعات",
              className:
                "self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg",
            }}
            onSend={async (data: FormData) => {
              if (id) {
                data.append("_method", "PUT");
                await update("manager/videos", data, id);
                if (paginate) {
                  navigate(`/videos?paginate=${paginate}`);
                } else {
                  navigate(`/videos`);
                }
              } else {
                await store("manager/videos", data);
                if (paginate) {
                  navigate(`/videos?paginate=${paginate}`);
                } else {
                  navigate(`/videos`);
                }
              }
            }}
          >
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-5">
              <Input.Defualt
                inputProps={{
                  name: "name",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.name,
                }}
                labelProps={{ children: "نام ویدئو" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "slug",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.slug,
                }}
                labelProps={{ children: "آدرس URL" }}
              />
              <Input.Select
                url="/manager/videos/create?select=category"
                name="category_id"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: "انتخاب دسته بندی",
                  defaultValue: 1,
                }}
                defaultValue={item?.category_id}
              />
              {/* <Input.Select
                                url='/manager/videos/create?select=artist'
                                name='artist_id'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "انتخاب هنرمند",
                                    defaultValue: 1
                                }}
                                defaultValue={item?.artist_id}
                            /> */}
              <Input.File
                inputProps={{
                  name: "urls[normal]",
                  accept: "video/*",
                }}
                fileCover={{
                  inputProps: {
                    name: "cover",
                  },
                  onChange: (cover) => {
                    setItem({ cover: cover.url });
                  },
                }}
                labelProps={{ children: "فایل با کیفیت معمولی" }}
                placeholder="فایل را انتخاب کنید"
              />
              <Input.File
                inputProps={{
                  name: "urls[hq]",
                  accept: "video/*",
                }}
                fileCover={{
                  inputProps: {
                    name: "cover",
                  },
                  onChange: (cover) => {
                    setItem({ cover: cover.url });
                  },
                }}
                labelProps={{ children: "فایل با کیفیت عالی(HQ)" }}
                placeholder="فایل را انتخاب کنید"
              />
              <Input.Defualt
                inputProps={{
                  name: "info[about]",
                  placeholder: " درباره فیلم را وارد نمایید",
                  defaultValue: item?.info?.about,
                }}
                labelProps={{ children: "درباره فیلم" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "info[director]",
                  placeholder: " کارگردان  را وارد نمایید",
                  defaultValue: item?.info?.director,
                }}
                labelProps={{ children: "کارگردان " }}
              />
              <Input.Defualt
                inputProps={{
                  name: "info[writer]",
                  placeholder: "نویسنده را وارد نمایید",
                  defaultValue: item?.info?.writer,
                }}
                labelProps={{ children: "نویسنده" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "info[release_year]",
                  placeholder: "سال تولید را وارد نمایید",
                  defaultValue: item?.info?.release_year,
                }}
                labelProps={{ children: "سال تولید" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "seo[title]",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.seo?.title,
                }}
                labelProps={{ children: "عنوان صفحه" }}
              />

              <Input.Select
                options={[
                  {
                    label: "غیر فعال",
                    value: 0,
                  },
                  {
                    label: "فعال",
                    value: 1,
                  },
                ]}
                name="status"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: "وضعیت",
                }}
                defaultValue={item?.status || 1}
              />
              <Input.Select
                options={[
                  {
                    label: "نمایش داده نشود",
                    value: 0,
                  },
                  {
                    label: "نمایش داده شود ",
                    value: 1,
                  },
                ]}
                name="home_visibility"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: "وضعیت نمایش در صفحه",
                }}
                defaultValue={item?.home_visibility}
              />
            </div>
            <div className="my-5">
              <Input.Textarea
                inputProps={{
                  name: "seo[schema]",
                  style: { direction: "ltr", textAlign: "left" },
                  placeholder: "schema code  ...",
                  defaultValue: item?.seo?.schema,
                }}
                labelProps={{ children: "اسکیما " }}
              />
            </div>
            <div className="my-5">
              <Input.Textarea
                inputProps={{
                  name: "seo[description]",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.seo?.description,
                }}
                labelProps={{ children: "توضیحات سئو " }}
              />
            </div>
            <div className="my-5">
              <Input.Preview
                defaultValue={item?.cover}
                labelProps={{ children: "کاور ویدئو" }}
                inputProps={{ name: "cover" }}
              />
            </div>
          </Form>
        </div>
      )}
    </Fragment>
  );
};

export default Creation;
