import { useAuthContext } from "@context";
import axios, { AxiosRequestConfig } from "axios";
import toast from "react-hot-toast";
const axiosInstance = axios.create({
  baseURL: "https://api.khorasanmedia.ir/api/",
});

const useRequest = () => {
  const { user, logout } = useAuthContext();

  const initialOptions = {
    method: "POST",
    headers: { Authorization: "Bearer " + user?.token },
  };

  const send = async (option: AxiosRequestConfig) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    option = { ...option, cancelToken: source.token };

    return await axiosInstance({ ...initialOptions, ...option })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          toast.success(
            response?.data?.message || "درخواست با موفقیت انجام شد"
          );
          return true;
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return false;
        }

        if (error.response) {
          if (error.response?.status === 401) {
            logout();
            return false;
          } else {
            if (typeof error.response?.data?.message == "object") {
              Object.entries(error.response?.data?.message).map((errors) => {
                if (Array.isArray(errors[1])) {
                  errors[1].map((item) => {
                    toast.error(item);
                  });
                } else {
                  let message = errors[1] as string;
                  toast.error(message);
                }
              });
            } else {
              toast.error(
                error.response?.data?.message || "مشکلی پیش آمده است"
              );
            }
            return false;
          }
        }
      });
  };

  return { send };
};

export default useRequest;
