import { useEffect, useState } from 'react'
import moment, { Moment } from 'moment-jalaali'
moment.loadPersian();


function getDaysOfMonth(month: Moment) {
    const days = [];

    const current = month.clone().startOf('jMonth');
    const end = month.clone().endOf('jMonth');
    current.subtract((current.day() + 1) % 7, 'days');
    end.add(6 - (end.day() + 1) % 7, 'days');

    while (current.isBefore(end)) {
        days.push(current.clone());
        current.add(1, 'days');
    }

    return days;
}


const Clock = () => {

    const [time, settime] = useState<string>();
    useEffect(() => {
        let interval = setInterval(changeTime, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);
    const changeTime = () => {
        const now = moment();
        settime(now.format("hh:mm:ss"))
    }
    return (
        <div>{time}</div>
    );
}

const Calendar = () => {
    const now = moment();
    const days = getDaysOfMonth(now);
    const dayOfWeekNames = ['شنبه', 'یکشنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنجشنبه', 'جمعه'];
    return (
        <div className='calendar'>
            <div>
                <div className="flex items-center md:text-[15px]  text-[12px] font-bold mb-2 justify-between gap-2">
                    <div >{now.format("dddd jDD jMMMM jYYYY")}</div>
                    <Clock />
                </div>
                <div className="grid grid-cols-7 gap-1 py-4 w-full font-semibold text-[7px] sm:text-[9px] text-center">
                    {dayOfWeekNames.map((day) => <p className='col-span-1'>{day}</p>)}

                </div>
            </div>
            <div className='grid grid-cols-7'>
                {days.map((day) => {
                    const disabled = moment(day.format("jYYYY-jM")).isBefore(now.format("jYYYY-jM"), 'month') || moment(day.format("jYYYY-jM")).isAfter(now.format("jYYYY-jM"), 'month')
                    const today = day.format("jYYYY-jM-jD") === now.format("jYYYY-jM-jD")
                    return <div className='col-span-1 mx-auto font-bold text-[12px] rounded-lg flex items-center justify-center w-7 h-7' style={{ color: disabled ? "#B0B0B8" : today ? "#fff" : "#000", background: today ? "#FA3667" : "tranparent" }}>{day.format("jD")}</div>
                })}
            </div>

        </div>
    )
}

export default Calendar