import { useAppContext, useAuthContext } from '@context'
import { Link } from 'react-router-dom';
const Header = () => {
    const { toggleSide, pageTitle } = useAppContext();
    const { user } = useAuthContext();
    return (
        <header className="sticky z-[98] top-0 left-0 w-full px-3 py-5 bg-whiteBack">
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-x-3'>
                    <div onClick={() => { toggleSide() }}>
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            data-name="Flat Line"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon flat-line lg:hidden"
                        >
                            <path
                                d="M3 12h18M9 18h12M3 6h12"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeWidth: 2,
                                }}
                            />
                        </svg>
                    </div>

                    <h2 className='text-md lg:text-2xl font-medium text-black'>{pageTitle}</h2>

                </div>
                <div className='flex justify-end items-center'>
                    <Link to="/settings/profile"> <img src={user?.profile || require('@images/avatar.png')} onError={(e) => e.currentTarget.src = require('@images/avatar.png')} alt="avatar" className='w-[40px] ms-3 h-[40px] object-cover rounded-full bg-white' /></Link>
                </div>
            </div>
        </header>
    )
}

export default Header