
import { Menu } from '@components/layout'
import { Route, Routes } from 'react-router'
import List from './list'
import Creation from './creation'

const Users = () => {
 
    return (
        <Menu menus={[
            {
                label: 'لیست مدیران',
                to: '/users/admins/admin',
                type: "list"
            },
            {
                label: 'لیست مدیران میانی',
                to: '/users/managers/seo',
                type: "list"
            },
            {
                label: 'لیست کاربران',
                to: '/users',
                type: "list"
            },
            {
                label: 'ایجاد کاربر',
                to: '/users/add'
            }
        ]}>
            <Routes>
                <Route path='/' element={<List />} />
                <Route path='/admins/:role' element={<List />} />
                <Route path='/managers/:role' element={<List />} />
                <Route path='/add' element={<Creation />} />
                <Route path='/edit/:id' element={<Creation />} />
            </Routes>
        </Menu>
    )
}

export default Users