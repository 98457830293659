import React from 'react'
interface Props {
    head: Array<{
        label: string,
        className?: string
    }>,
    rows: Array<object>,
    renderItem: Function,
    loading?: boolean
}
const Table = ({ rows, head, renderItem, loading = true }: Props) => {
    return (
        <div className='overflow-x-auto'>
            <div className='align-middle'>
                <div className='relative'>
                    <table className='table-fixed min-w-full'>
                        <thead className='bg-white50'>
                            <tr>
                                {head.map((th, i) => {
                                    return <th key={i} className={th.className ? th.className : "py-2 text-sm md:text-md"}>{th.label}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody className='text-sm md:text-md'>{loading ?
                            Array.from(Array(5).keys()).map((x) =>
                                <tr className='animate-pulse' key={x}>
                                    <td className='flex justify-center'>
                                        <div className="rounded-lg bg-white100 h-[50px] w-[50px]"></div>
                                    </td>
                                    {Array.from(Array(head.length - 1).keys()).map((y) =>
                                        <td>
                                            <div key={y} className="h-[25px] bg-white100 mx-3 rounded"></div>
                                        </td>
                                    )}
                                </tr>
                            )
                            : rows.length ?
                                rows.map((row, i) => {
                            
                                    return renderItem(row, i)
                                }) :
                                <tr>
                                    <td colSpan={head.length} className='text-center py-10'>
                                        موردی وجود ندارد
                                    </td>
                                </tr>
                        }</tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default Table