import { ButtonHTMLAttributes, FormEvent, FormHTMLAttributes, Fragment, PropsWithChildren, ReactElement, createRef, useState } from 'react'
import Loader from './loader'
import { FormLoading } from '@components/common';
interface Props extends PropsWithChildren {
    formProps?: FormHTMLAttributes<HTMLFormElement>,
    buttonProps: ButtonHTMLAttributes<HTMLButtonElement>,
    loader?: ReactElement | any,
    onSend: Function,
    progress?: number
}
const Form = (props: Props) => {
    const form = createRef<HTMLFormElement>();


    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        let formData = new FormData();
        setLoading(true);
        let inputs = form.current?.querySelectorAll("input,select,textarea") as object;

        for (const [key, input] of Object.entries(inputs)) {
            if (String(input.name).length) {

                if (input.type == "radio") {
                    if (input.checked) {
                        formData.append(input.name, input.value);
                    }
                } else {
                    if (input.type === "file") {
                        if (input.files[0]) {
                            formData.append(input.name, input.files[0]);
                        }
                    } else {
                        if (typeof input.value === "object") {
                            for (const entry of Object.entries(input.value)) {
                                let value = entry[1] as string | Blob | null;
                                if (value) {
                                    formData.append(input.name + "[]", value);
                                }
                            }
                        } else {
                            if (input.value) {
                                formData.append(input.name, input.value);
                            }
                        }
                    }
                }

            }
        }

        props.onSend(formData).then(() => {
            setLoading(false);
        })

    }


    return (
        <form ref={form} onSubmit={onSubmit} {...props.formProps}>
            <Fragment>
                {props.children}
                {loading ?
                    props.loader
                        ? props.loader()
                        :
                        <Loader progress={props.progress} className={props.buttonProps.className} />
                    :
                    <button type='submit' {...props.buttonProps} />
                }
            </Fragment>
        </form>
    )
}

export default Form