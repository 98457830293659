import { InputHTMLAttributes, LabelHTMLAttributes, PropsWithChildren, ReactElement, } from 'react'
import './index.css'
import Date from './date'
import Password from './password'
import Select from './select'
import Preview from './preview'
import File from './file'
import Textarea from './textarea'
import Time from './time'

interface Props extends PropsWithChildren {
    inputProps?: InputHTMLAttributes<HTMLInputElement>
    labelProps?: LabelHTMLAttributes<HTMLLabelElement>
    containerClassName?: string
}

const Defualt = (props: Props) => {
    return (
        <div className={'flex flex-col w-full ' + props.containerClassName}>
            <label className="text-sm font-medium md:text-md" {...props.labelProps} />
            <input className='input text-sm md:text-md' {...props.inputProps} />
            {props.children}
        </div>
    )
}

export default { Defualt, Password, Select, Preview, File, Textarea, Date, Time }