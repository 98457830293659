import React, { PropsWithChildren } from 'react'
interface Props extends PropsWithChildren {
    unit: string,
    value: string,
    details: string
}
const CardState = ({ unit, value, details, children }: Props) => {
    return (
        <div className="card">
            <div className="flex justify-between items-center">
                {children}
            </div>

            <h3 className="font-bold text-lg my-4">{value}  <span className="text-xs">{unit}</span></h3>

            <h3 className="text-white300  my-4">{details}</h3>

        </div>
    )
}

export default CardState