import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import "react-multi-date-picker/styles/layouts/mobile.css"
import { LabelHTMLAttributes, useEffect, useState } from "react";
import { pTeNumbers } from "@components/utils";
interface Props {
    containerClassName?: string,
    labelProps: LabelHTMLAttributes<HTMLLabelElement>,
    name: string,
    placeholder?: string,
    defaultValue?: any,
    disableDayPicker?: boolean,

}
const InputTime = ({ containerClassName, labelProps, name, placeholder, defaultValue, disableDayPicker = true }: Props) => {
    const [value, setValue] = useState<string>(pTeNumbers(`${new Date().toLocaleTimeString('fa')} ${new Date().toLocaleDateString('fa')}`))
    useEffect(() => {
        if (defaultValue) {
            setValue(pTeNumbers(`${new Date(defaultValue).toLocaleTimeString('fa')} ${new Date(defaultValue).toLocaleDateString('fa')}`))
        }
    }, [defaultValue])

    return (
        <div className={'flex flex-col w-full ' + containerClassName}>
            <label className="text-sm font-medium md:text-md" {...labelProps} />
            <DatePicker
                name={name}
                placeholder={placeholder}
                disableDayPicker={disableDayPicker}
                onChange={(date: any) => {
                    console.log(new Date(date).toLocaleTimeString('fa'))
                    setValue(pTeNumbers(`${new Date(date).toLocaleTimeString('fa')} ${new Date(date).toLocaleDateString('fa')}`))
                }}
                value={value}
                format="HH:mm  YYYY-MM-DD"
                plugins={[
                    <TimePicker hideSeconds />
                ]}
                className="rmdp-mobile"
                calendar={persian}
                locale={persian_fa}
                calendarPosition="bottom-right"
            />
        </div>
    )
}

export default InputTime;