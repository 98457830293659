import { LabelHTMLAttributes, PropsWithChildren, TextareaHTMLAttributes } from "react"


interface Props extends PropsWithChildren {
    inputProps?: TextareaHTMLAttributes<HTMLTextAreaElement>
    labelProps?: LabelHTMLAttributes<HTMLLabelElement>
    containerClassName?: string
}

const Textarea = (props: Props) => {
    return (
        <div className={'flex flex-col w-full ' + props.containerClassName}>
            <label className="text-sm font-medium md:text-md" {...props.labelProps} />
            <textarea className='input text-sm md:text-md'  {...props.inputProps} />
            {props.children}
        </div>
    )
}

export default Textarea