import { Form, Input } from '@components'
import { CardHeader, FormLoading } from '@components/common'
import { API } from '@hooks'
import { Fragment, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

const Creation = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { item, find, setItem, loading, setLoading, update, store } = API.useApi();
    const { id } = useParams();
    let paginate = new URLSearchParams(location.search).get('paginate');
    useEffect(() => {
        setLoading(true)
        if (id) {
            find('/manager/users', id)
        } else {
            setItem(null);
            setTimeout(() => {
                setLoading(false);
            }, 10);
        }
        console.log(paginate)
    }, [id])



    return (
        <Fragment>
            <CardHeader label={id ? 'ویرایش کاربر' : 'ایجاد کاربر جدید'} textButton='لیست کاربران' toButton='/users' />
            {loading ? <FormLoading /> :
                <div className='my-10 '>
                    <Form
                        formProps={{ className: 'flex flex-col' }}
                        buttonProps={{ children: "ثبت اطلاعات", className: 'self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg' }}
                        onSend={async (data: FormData) => {
                            if (id) {
                                data.append('_method', 'PUT');
                                await update('manager/users', data, id);
                                if (paginate) {
                                    navigate(`/users?paginate=${paginate}`)
                                } else {
                                    navigate(`/users`)
                                }
                            } else {
                                await store('manager/users', data);
                                if (paginate) {
                                    navigate(`/users?paginate=${paginate}`)
                                } else {
                                    navigate(`/users`)
                                }
                            }
                        }}>
                        <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-5'>
                            <Input.Defualt
                                inputProps={{ name: 'name', placeholder: "نام کاربر را وارد نمایید", defaultValue: item?.name }}
                                labelProps={{ children: "نام کاربر" }}
                            />
                            <Input.Defualt
                                inputProps={{ name: 'mobile', placeholder: "متن را وارد نمایید", defaultValue: item?.mobile }}
                                labelProps={{ children: "شماره تماس" }}
                            />
                            <Input.Defualt
                                inputProps={{ name: 'username', placeholder: "نام کاربری باید به صورت لاتین وارد شود", defaultValue: item?.username }}
                                labelProps={{ children: "نام کاربری" }}
                            />
                            <Input.Select
                                name='role'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: " سطح دسترسی کاربر"
                                }}
                                options={[
                                    {
                                        label: "کاربر عادی",
                                        value: "client"
                                    },
                                    {
                                        label: "مدیر محتوا",
                                        value: "seo"
                                    },
                                    {
                                        label: "مدیر اصلی",
                                        value: "admin"
                                    }
                                ]}
                                defaultValue={item?.role}
                            />


                            <Input.Select
                                options={[{
                                    label: "غیر فعال",
                                    value: 0
                                },
                                {
                                    label: "فعال",
                                    value: 1
                                }]}
                                name='status'
                                placeHolder='لطفا یک مورد را انتخاب کنید'
                                labelProps={{
                                    children: "وضعیت کاربر"
                                }}
                                defaultValue={item?.status || 1}
                            />

                            <Input.Password
                                inputProps={{ name: "password", placeholder: "کلمه عبور را وارد نمایید" }}
                                labelProps={{ children: "کلمه عبور" }}
                            />

                        </div>
                        <div className='my-5'>
                            <Input.Preview
                                defaultValue={item?.profile}
                                labelProps={{ children: "تصویر کاربر" }}
                                inputProps={{ name: "profile" }}
                            />
                        </div>
                    </Form>

                </div>
            }
        </Fragment>
    )
}

export default Creation