
import { Menu } from '@components/layout'
import { Route, Routes } from 'react-router'
import List from './list'
import Creation from './creation'

const Videos = () => {

    return (
        <Menu menus={[
            {
                label: 'لیست ویدئوها',
                to: '/videos',
                type: "list"
            },
            {
                label: 'ایجاد ویدئو جدید',
                to: '/videos/add'
            }
        ]}>
            <Routes>
                <Route path='/' element={<List />} />
                <Route path='/add' element={<Creation />} />
                <Route path='/edit/:id' element={<Creation />} />
            </Routes>
        </Menu>
    )
}

export default Videos