import { Fragment, useEffect } from 'react'
import Router from '@router'
import { Toaster } from 'react-hot-toast'
import { AppContext, AuthContext } from '@context'
import packageJSON from '../../package.json'
const checkVersion = () => {
    const version = localStorage.getItem('version');
    if (version !== packageJSON.version) {
        caches.keys().then(names => {
            names.forEach(name => {
                caches.delete(name)
            })
        })
        localStorage.setItem('version', packageJSON.version)
        window.location.reload();
    }
}


const App = () => {
    useEffect(() => {
        checkVersion();
    }, [])

    return (
        <Fragment>
            <AuthContext>
                <AppContext>

                        <Router />
                </AppContext>
            </AuthContext>
            <Toaster position='bottom-center' />
        </Fragment>
    )
}
export default App