import { Fragment, useEffect, useState } from 'react'
import { AsyncDelete, CardHeader, Modal, Paginate, Table } from '@components/common'
import { API } from '@hooks'
import { queryGenerate, toJalali } from '@components/utils'
import Upgrade from '@views/users/upgrade'
import { Form, Input } from '@components'
import Icons from '@assets/icons'
const Upgrades = () => {
    const { index, loading, destroy, update, list, paginate } = API.useApi();
    const [showUpgrade, setShowUpgrade] = useState<number>(-1)
    const [reject, setReject] = useState<number>(-1)

    useEffect(() => {
        index('/manager/upgrade')
    }, [])

    return (
        <Fragment>
            <CardHeader label='لیست درخواست ارتقا حساب کاربری' />
            <div className='my-10'>

                <Table
                    loading={loading}
                    head={[
                        {
                            label: "نام کاربر"
                        },
                        {
                            label: "شماره تماس"
                        },
                        {
                            label: "تصویر کارت ملی"
                        },
                        {
                            label: "وضعیت درخواست"
                        },
                        {
                            label: "توضیحات"
                        },
                        {
                            label: "تاریخ ثبت"
                        },
                        {
                            label: "مدیریت"
                        },
                    ]}
                    rows={list || []}
                    renderItem={(upgrade: any, i: number) => {

                        return (
                            <tr className='text-center' key={i}>
                                <td>{upgrade.user?.name}</td>
                                <td>{upgrade.user?.mobile}</td>
                                <td><a className='flex justify-center' href={upgrade.attachments} target='_blank'><img src={upgrade.attachments} alt="" style={{ width: 100, height: 60, objectFit: "contain" }} /></a></td>
                                <td>{upgrade.status == 'wait' ? <span className='bg-slate-400 text-white px-2 py-1 rounded'>درحال بررسی</span> : upgrade.status == 'reject' ? <span className='bg-red-500 text-white px-2 py-1 rounded'>رد شده</span> : 'تایید شده'}</td>
                                <td>{upgrade.message || '-'}</td>
                                <td>{toJalali(upgrade.created_at, "jDD jMMMM jYYYY")}</td>
                                <td>
                                    <div className='flex justify-center items-center gap-x-5'>
                                        <td><div onClick={() => setShowUpgrade(i)} className='bg-green-500 text-white w-[100px] h-[30px] flex justify-center items-center rounded-lg mx-auto'>ارتقا حساب</div></td>
                                        {upgrade.status == 'reject' ? null : <td><div onClick={() => setReject(i)} className='bg-red-500 text-white w-[100px] h-[30px] flex justify-center items-center rounded-lg mx-auto'>رد درخواست</div></td>}
                                        <AsyncDelete onDestroy={async () => await destroy('/manager/upgrade', upgrade.id)} />
                                    </div>
                                </td>
                            </tr>
                        )
                    }}
                />
            </div>
            <Paginate {...paginate} onChange={(link: string) => {
                index(queryGenerate(link));
            }} />
            <Modal visable={showUpgrade >= 0} onHide={() => { setShowUpgrade(-1) }} >
                {list[showUpgrade]?.user && <Upgrade
                    onSend={async (data: FormData) => { data.append('_method', 'PUT'); data.append('status', 'accept'); await update("/manager/upgrade", data, list[showUpgrade]?.id).then(() => index('/manager/upgrade')); setShowUpgrade(-1) }}
                    onHide={() => setShowUpgrade(-1)}
                    user={list[showUpgrade]?.user} />}
            </Modal>
            <Modal visable={reject >= 0} onHide={() => { setReject(-1) }} >
                <div>
                    <div className='bg-white p-5'>
                        <div className='flex items-center justify-between'>
                            <div>رد درخواست کاربر {list[reject]?.user?.name}</div>
                            <div onClick={() => { setReject(-1) }}>
                                <Icons.Close color='#000' /></div>
                        </div>
                        <Form onSend={async (data: FormData) => {
                            data.append('_method', 'PUT')
                            update('/manager/upgrade', data, list[reject]?.id).then(() => index('/manager/upgrade'))
                            setReject(-1)
                        }}
                            formProps={{ className: 'flex flex-col mt-5' }}
                            buttonProps={{ children: "ثبت درخواست", className: 'self-center px-3 py-2 mt-3 text-white bg-primary rounded-lg' }}>
                            <input type="hidden" name='status' value='reject' />
                            <Input.Defualt
                                inputProps={{ name: "message" }}
                                labelProps={{ children: "توضیحات رد درخواست" }}
                            />
                        </Form>
                    </div>
                </div>
            </Modal>
        </Fragment >
    )
}

export default Upgrades;