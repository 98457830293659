import { Menu } from '@components/layout'
import { Route, Routes } from 'react-router'
import List from './list'
import Creation from './creation'
import Upgrades from './upgrades'

const Artists = () => {

    return (
        <Menu menus={[
            {
                label: 'لیست هنرمندان',
                to: '/artists',
                type: "list"
            },
            {
                label: 'ایجاد هنرمند',
                to: '/artists/add'
            },
            {
                label: 'لیست درخواست ارتقا',
                to: '/artists/upgrades',
                type: "list"
            },
        ]}>
            <Routes>
                <Route path='/' element={<List />} />
                <Route path='/add' element={<Creation />} />
                <Route path='/edit/:id' element={<Creation />} />
                <Route path='/upgrades' element={<Upgrades />} />
            </Routes>
        </Menu>
    )
}

export default Artists