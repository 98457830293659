interface Props {
    name: string,
    time: string,
    image: string,
    onClick: Function
}

const CardUser = ({ name, time, image, onClick }: Props) => {
    return (
        <div className='flex items-center justify-between border-b py-3 last:border-none'>
            <div className='flex gap-2 items-center'>
                <img src={image || require('@images/profile.png')} onError={(e) => {
                    console.log(e)
                    e.currentTarget.src = require('@images/profile.png')
                }} alt={name} className='w-[50px] h-[50px] object-cover rounded-lg' />
                <div>
                    <h4 className='font-semibold text-sm'>{name}</h4>
                    <p className='text-white700 text-xs'>{time}</p>
                </div>
            </div>
            <div onClick={() => { onClick() }} className='font-medium text-sm'>جزئیات</div>
        </div>
    )
}

export default CardUser