import { PropsWithChildren, createRef, useEffect } from 'react'
interface Props extends PropsWithChildren {
    onHide: Function,
    visable: boolean,
}
const Modal = ({ onHide, children, visable }: Props) => {

    const content = createRef<HTMLDivElement>();


    useEffect(() => {

        const onClickHendler = (e: any) => {
            if (content.current && !content.current.contains(e.target)) {
                onHide()
            }
        }

        document.addEventListener('mousedown', onClickHendler)
        return () => {
            document.removeEventListener('mousedown', onClickHendler)
        }

    }, [content])


    return (
        <div className={` relative z-[110] ${visable ? "block" : "hidden"} `} aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed inset-0 z-[110] bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-[110] overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">

                    {visable ? <div ref={content} className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        {children}
                    </div> : null}
                </div>
            </div>
        </div>

    )
}

export default Modal