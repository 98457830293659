import { PropsWithChildren, memo, useEffect, useState } from "react"


interface Props extends PropsWithChildren {
    defultValue?: Array<any>,
    label: string,
    render: any
    onAddView?: (views: Array<any>) => void
}

const AddView = ({ defultValue = [1], label, render, onAddView = () => { } }: Props) => {
    const [views, setviews] = useState(defultValue ?? []);
    useEffect(() => {
        setviews(defultValue)
    }, [defultValue])
    useEffect(() => {
        onAddView(views)
    }, [views])
    console.log(views)
    return (
        <div className="">
            <div className="flex justify-between items-center pb-5">
                <h3 className='sm:text-lg text-sm font-medium'>{label}</h3>
                <div className='self-end px-9 py-2 text-white bg-secondary400 rounded-lg' onClick={() => {
                    let newViews = [...views];
                    newViews.push(Math.random())
                    setviews(newViews)
                }}>افزودن</div>
            </div>

            {views.map((view, i) =>
                <div className="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="3" viewBox="0 0 100% 3" fill="none">
                        <line x1="2.49707" y1="1.5" x2="100%" y2="1.5" stroke="#C6C6CC" stroke-width="3" stroke-linecap="round" stroke-dasharray="15 15" />
                    </svg>
                    <div className={`py-5 px-2`} key={view}>
                        <div className="flex justify-end">
                            <div className="px-7 py-2 text-white bg-primary800 rounded-lg" onClick={() => {
                                let newViews = [...views].filter((v, x) => x != i);
                                setviews(newViews);
                            }}>حذف مورد</div>
                        </div>
                        {render(view, i)}
                    </div>
                </div>)}
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="3" viewBox="0 0 100% 3" fill="none">
                <line x1="2.49707" y1="1.5" x2="100%" y2="1.5" stroke="#C6C6CC" stroke-width="3" stroke-linecap="round" stroke-dasharray="15 15" />
            </svg>
        </div>
    )
}

export default memo(AddView)