
import { Menu } from '@components/layout'
import { Route, Routes } from 'react-router'
import List from './list'
import Creation from './creation'
const Albums = () => {

    return (
        <Menu menus={[
            {
                label: 'لیست آلبوم ها',
                to: '/albums',
                type: "list"
            },
            {
                label: 'ایجاد آلبوم',
                to: '/albums/add'
            }
        ]}>
            <Routes>
                <Route path='/' element={<List />} />
                <Route path='/add' element={<Creation />} />
                <Route path='/edit/:id' element={<Creation />} />
            </Routes>
        </Menu>
    )
}

export default Albums