import Layout from '@components/layout';
import { useAuthContext } from '@context'
import { Albums, Artists, Categories, Home, Login, Musics, Settings, Users, Videos } from '@views'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'

const Router = () => {
    const { user } = useAuthContext();
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/*' element={
                    user ?
                        <Layout>
                            <Routes>
                                <Route path='/' element={<Home />} />
                                <Route path='/categories/*' element={<Categories />} />
                                <Route path='/albums/*' element={<Albums />} />
                                <Route path='/users/*' element={<Users />} />
                                <Route path='/musics/*' element={<Musics />} />
                                <Route path='/videos/*' element={<Videos />} />
                                <Route path='/artists/*' element={<Artists />} />
                                <Route path='/settings/*' element={<Settings />} />
                                <Route path='*' element={<Navigate to={"/"} />} />
                            </Routes>
                        </Layout>
                        :
                        <Navigate to="/login" />
                } />
                <Route path='/login' element={user ? <Navigate to={"/"} /> : <Login />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router