import { Fragment, useEffect, useState } from 'react'
import { AsyncDelete, CardHeader, Modal, Paginate, SearchBox, Table } from '@components/common'
import { Link, useLocation, useParams } from 'react-router-dom'
import Icons from '@assets/icons'
import { useCRUD } from '@context'
import Upgrade from './upgrade'
import { API } from '@hooks'
import { queryGenerate } from '@components/utils'
const List = () => {
    const { list, loading, paginate, index, destroy } = API.useApi();
    const { store } = API.useApi();
    const { role } = useParams();
    const location = useLocation();
    const [query, setQuery] = useState<string>("?role=client")
    const [text, setText] = useState<string>('')
    useEffect(() => {
        let new_week = new URLSearchParams(location.search).get('new_week');
        let newQuery = "";
        if (role) {
            newQuery += "?role=" + role;
        } else {
            newQuery += "?role=client";
        }
        if (new_week) {
            newQuery += "?new_week=1"
        }

        if (text.length) {
            newQuery += "?text=" + text
        }
        setQuery(newQuery)


    }, [role, location.search, text])

    let changeablepaginate = new URLSearchParams(location.search).get('paginate');
    useEffect(() => {
        let querypaginate = new URLSearchParams(location.search).get('paginate');
        const timeOutId = setTimeout(() => index(queryGenerate(querypaginate ? querypaginate + query : '/manager/users' + query)), 100);
        return () => clearTimeout(timeOutId);

    }, [query, changeablepaginate])

    const [showUpgrade, setShowUpgrade] = useState<number>(-1)

    return (
        <Fragment>
            <CardHeader label='لیست کاربران ها' textButton='ایجاد کاربر جدید' toButton='/users/add' />
            <div className='my-10'>
                <div className='mb-5 px-2 grid lg:grid-cols-4'>
                    <SearchBox onChange={(value: string) => {
                        setText(value)
                    }} />
                </div>
                <Table loading={loading} head={[
                    {
                        label: "تصویر"
                    },
                    {
                        label: "نام کاربر"
                    },
                    {
                        label: "نام کاربری"
                    },
                    {
                        label: "شماره تماس"
                    },
                    {
                        label: "ارتقا کاربر"
                    },
                    {
                        label: "مدیریت"
                    },
                ].filter((x) => {
                    if (role) {
                        if (x.label != "ارتقا کاربر") {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }

                })}
                    rows={list || []}
                    renderItem={(user: any, i: number) => {

                        return (
                            <tr className='text-center' key={i}>
                                <td>
                                    <div className='flex justify-center'>
                                        <img className='w-[50px] h-[50px] object-cover rounded-lg' src={user.profile || require('@images/no-photo.png')} alt={user.name} />
                                    </div>
                                </td>
                                <td>{user.name}</td>
                                <td>{user.username}</td>
                                <td>{user.mobile}</td>
                                {role ? null :
                                    <td><div onClick={() => setShowUpgrade(i)} className='bg-primary text-white w-[100px] h-[30px] flex justify-center items-center rounded-lg mx-auto'>ارتقا حساب</div></td>
                                }
                                <td >
                                    <div className='flex justify-center items-center gap-x-5'>
                                        <AsyncDelete onDestroy={async () => await destroy('/manager/users', user.id)} />
                                        <Link to={changeablepaginate
                                        ?
                                             '/users/edit/' + user.id + "?paginate=" + changeablepaginate 
                                             : 
                                             '/users/edit/' + user.id}>
                                            <Icons.Edit />
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        )
                    }}
                />
            </div>
            <Paginate {...paginate} onChange={(link: string) => {
                index(queryGenerate(link + query));
            }} />
            <Modal visable={showUpgrade >= 0} onHide={() => { setShowUpgrade(-1) }} >
                {list[showUpgrade] && <Upgrade
                    onSend={async (data: FormData) => { await store("/manager/upgrade-user", data) }}
                    onHide={() => setShowUpgrade(-1)}
                    user={list[showUpgrade]} />}
            </Modal>

        </Fragment>
    )
}

export default List;