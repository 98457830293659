import { useRequest } from '@hooks'
import { LabelHTMLAttributes, useEffect, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'

interface Props {
    options?: Array<{ label: string, value: any }>,
    defaultValue?: Array<any> | any,
    labelProps?: LabelHTMLAttributes<HTMLLabelElement>,
    containerClassName?: string,
    placeHolder?: string,
    name?: string,
    multi?: boolean
    url?: string
}

const Select = ({ options, defaultValue = [], containerClassName, labelProps, placeHolder = "", name = "", url, multi }: Props) => {

    const request = useRequest();
    const [selected, setSelected] = useState<Array<{ label: string, value: any }>>([]);
    const [loading, setloading] = useState(false)
    const [Data, setData] = useState<Array<{ label: string, value: any }>>([])
    useEffect(() => {
        if (options) {
            if (Array.isArray(defaultValue)) {
                if (defaultValue.length) {
                    setSelected([...options.filter((x) => defaultValue.findIndex((y) => y === x.value) + 1)])
                }
            } else {
                if (defaultValue >= 0 || typeof defaultValue == 'string') {
                    setSelected(options.filter((x) => x.value == defaultValue))
                } else {
                    setSelected([])
                }
            }
            setData(options)
        }
    }, [defaultValue, options])

    useEffect(() => {
        if (Array.isArray(defaultValue)) {
            if (defaultValue.length) {
                setSelected([...Data.filter((x) => defaultValue.findIndex((y) => y == x.value) + 1)])
            }
        } else {
            if (defaultValue >= 0 || typeof defaultValue == 'string') {
                setSelected(Data.filter((x) => x.value == defaultValue))
            } else {
                setSelected([])
            }
        }
    }, [defaultValue, Data])


    useEffect(() => {
        if (url) {
            setloading(true)
            request.send({ method: "get", url }).then((response) => {
                setloading(false);
                if (response) {
                    setData(response);
                }
            })
        }
        return () => {
            setData([])
        }
    }, [url])



    return (
        <div className={'flex flex-col w-full ' + containerClassName}>
            <label className="text-sm font-medium md:text-md" {...labelProps} />
            {selected.map((item) => {
                return <input type="hidden" name={name + (selected.length > 1 ? '[]' : '')} value={item.value} />
            })}
            <MultiSelect isCreatable={true} isLoading={loading} value={selected} closeOnChangedValue={true} hasSelectAll={false} valueRenderer={(value) => {
                if (value.length) {
                    return <div className='flex flex-row gap-x-2'>{value.map((item, i) => {
                        return (
                            <div key={i}>{item.label} {(value.length - 1) != i ? "," : ""}</div>
                        )
                    })}</div>
                } else {
                    return <div className='text-sm md:text-md'>{placeHolder}</div>
                }
            }}
                className='input p-[3px]'
                options={Data}
                overrideStrings={{
                    "allItemsAreSelected": "همه موارد انتخاب شده",
                    "clearSearch": "خالی کردن جستجو",
                    "clearSelected": "خالی کردن انتخاب ها",
                    "noOptions": "موردی پیدا نشد",
                    "search": "جستجو",
                    "selectAll": "انتخاب همه",
                    "selectAllFiltered": "انتخاب همه موارد",
                    "selectSomeItems": "انتخاب کنید",
                    "create": "ایجاد کردن",
                }}
                ItemRenderer={({
                    checked,
                    option,
                    onClick,
                    disabled,
                }: any) => (
                    <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
                        <input
                            className='opacity-0'
                            type="checkbox"
                            onChange={onClick}
                            checked={checked}
                            tabIndex={-1}
                            disabled={disabled}
                        />
                        <span>{option.label}</span>
                    </div>
                )}
                onChange={(value: any) => {

                    if (multi) {
                        setSelected(value);
                    } else {
                        if (value.length) {
                            setSelected([value[value.length - 1]])
                        }
                    }
                }}
                ClearSelectedIcon={null}
                labelledBy="Select" />
        </div>
    )
}

export default Select