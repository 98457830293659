import React, { PropsWithChildren, createRef, useEffect, useState } from 'react'

interface Props extends PropsWithChildren {
    diraction?: "right" | "left",
    menus?: Array<{
        label: string,
        onClick: Function
    }>
}

const DropDown = ({ menus, diraction, children }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = createRef<HTMLDivElement>();

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [dropdownRef]);

    return (
        <div className="relative">
            <button onClick={() => { setIsOpen(true); }}>
                <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 8">
                        <circle id="Ellipse 3" cx="10" cy="10" r="10" fill="#DCDCE0" />
                        <circle id="Ellipse 4" cx="10" cy="6" r="1" fill="black" />
                        <circle id="Ellipse 5" cx="10" cy="10" r="1" fill="black" />
                        <circle id="Ellipse 6" cx="10" cy="14" r="1" fill="black" />
                    </g>
                </svg>
            </button>

            {isOpen && (
                <div ref={dropdownRef} className={`absolute ${diraction == "right" ? 'right-0' : 'left-0'} w-40 rounded-xl overflow-hidden z-10 shadow-xl bg-white`}>
                    {menus && menus.map((x, i) => {
                        return (
                            <div key={i} onClick={() => { x.onClick() }} className="item hover:bg-[#F2F2FD] text-xs p-3">
                                <p>{x.label}</p>
                            </div>
                        )
                    })}
                    {children}
                </div>
            )}
        </div>
    );
}


export default DropDown