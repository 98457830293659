import { useFileManager } from '@hooks'
import { InputHTMLAttributes, LabelHTMLAttributes, PropsWithChildren, createRef, useEffect, useRef, useState } from 'react'
interface Props extends PropsWithChildren {
    inputProps?: InputHTMLAttributes<HTMLInputElement>
    labelProps?: LabelHTMLAttributes<HTMLLabelElement>
    containerClassName?: string,
    placeholder?: string,
    fileDuration?: {
        inputProps?: InputHTMLAttributes<HTMLInputElement>
    }
    fileCover?: {
        inputProps?: InputHTMLAttributes<HTMLInputElement>,
        onChange?: (result: { file: File, url: string }) => void
    }
}
const InputFile = (props: Props) => {
    const id = Math.round(Math.random() * 1000000);

    const [file, setFile] = useState<File | null>(null)
    const [duration, setDuration] = useState<string>('')
    const fileDuration = useFileManager();
    const fileCover = useFileManager();
    const cover = createRef<HTMLInputElement>()
    useEffect(() => {
        if (file) {
            if (props.fileDuration) {
                fileDuration.getDuration({ file, type: "audio" })
            }
            if (props.fileCover) {
                fileCover.generateVideoThumbnail(file).then((result) => {
                    if (props.fileCover && props.fileCover.onChange) {
                        props.fileCover.onChange(result);
                    }
                    const container = new DataTransfer();
                    container.items.add(result.file);
                    if (cover.current) {
                        cover.current.files = container.files;
                    }
                })
            }
        } else {
            setDuration('');

        }
    }, [file]);

    useEffect(() => {
        if (fileDuration.result && props.fileDuration) {
            setDuration(fileDuration.result)
        }
    }, [fileDuration.result]);

    return (
        <div className={'flex flex-col w-full relative ' + props.containerClassName}>
            <label htmlFor={'file-' + id} className="text-sm font-medium md:text-md" {...props.labelProps} />
            <div className='input h-[44px] text-sm md:text-md text-white400'>{file ? file.name : props.placeholder}</div>
            {props?.fileDuration ?
                <input type="hidden" {...props.fileDuration.inputProps} value={duration} />
                : null}
            {props?.fileCover ?
                <input type="file" ref={cover} style={{ opacity: 0, display: 'none' }} {...props.fileCover.inputProps} />
                : null}
            <input id={'file-' + id} onChange={({ target: { files } }) => {
                if (files?.length) {
                    setFile(files[0])
                } else {
                    setFile(null)
                }
            }} type='file' className='text-sm md:text-md absolute w-full h-full left-0 top-0' {...props.inputProps} />
        </div>
    )
}

export default InputFile